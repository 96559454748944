import {ModelationResult, ReportFilter, ReportFilterBank} from '@generated/model';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {groupBy, orderBy} from 'lodash';
import {ReportUiFilter} from '../report.model';
import {ReportState} from './report.state';

export const featureName = 'report';

export const selectState = createFeatureSelector<ReportState>(featureName);

export const selectFilter = createSelector(selectState, state => state.filter);

export const selectResults = createSelector(selectState, state => state && state.results.results);
export const selectFilteredResults = createSelector(selectResults, selectFilter, filterResults);

export const selectUnavailableModelations = createSelector(selectState, state => state && state.results.unavailableModelations);

export const selectUiFilter = createSelector(selectFilter, selectResults, convertToUiFilter);

export const selectModel = createSelector(selectFilteredResults, selectUiFilter,
  (results, filter) => ({results, filter}));

function convertToUiFilter(filter?: ReportFilter, results?: ModelationResult[]): ReportUiFilter {
  if (!filter) return null;

  const availableBanks = Object.keys(groupBy(results || [], 'bankCode'));
  const banks = [...filter.banks].sort(sortBanks).reduce((filterObj, {bankCode, isSet}) => ({
    ...filterObj,
    [bankCode]: availableBanks.includes(bankCode) ? isSet : null, // null == disabled
  }), {} as Record<string, boolean>);
  return {banks, sortOrder: filter.sortOrder, display: {...filter.display}};
}

function filterResults(results?: ModelationResult[], filter?: ReportFilter) {
  if (!results || !filter) return results;
  const {banks, display, sortOrder} = filter;
  const bankCodes = banks && banks.filter(b => b.isSet).map(b => b.bankCode);
  const individualExists = results.some(r => r.isIndividual);
  const containsIndividualByBankCode = (bankCode: string): boolean => {
    return results.filter(r => r.bankCode === bankCode).some(r => r.isIndividual);
  };
  results = results.filter(r =>
    (!bankCodes || bankCodes.includes(r.bankCode)) &&
    (!display.onlyCredit || r.isProvided) &&
    (!individualExists || display.individualStandards || !containsIndividualByBankCode(r.bankCode) || r.isIndividual),
  );
  if (display.anonymized) {
    results = results.map(r => ({
      ...r,
      bankCode: null,
      productName: null,
      bankRemarks: null,
      customRemarks: null,
      riskInsuranceTypes: null,
    }));
  }
  if (!display.showDtiValues) {
    results = results.map(r => ({
      ...r,
      dtiRemark: null,
    }));
  }
  return orderBy(results, sortOrder);
}

function sortBanks(b1: ReportFilterBank, b2: ReportFilterBank): number {
  return bankOrder.findIndex(code => code === b1.bankCode) - bankOrder.findIndex(code => code === b2.bankCode);
}

const bankOrder = ['HB', 'CS', 'KB', 'RB', 'UCB', 'MB'];
