import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {MatTab} from '@angular/material/tabs';

@Component({
  selector: 'mat-vertical-tab',
  templateUrl: './vertical-tab.component.html',
  styleUrls: ['./vertical-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalTabComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() dataCy?: string;

  @ViewChild(MatTab, {static: true}) matTab: MatTab;
}
