import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {VerticalTabGroupComponent} from './vertical-tab-group/vertical-tab-group.component';
import {VerticalTabComponent} from './vertical-tab/vertical-tab.component';

@NgModule({
  declarations: [
    VerticalTabGroupComponent,
    VerticalTabComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
  ],
  exports: [
    VerticalTabGroupComponent,
    VerticalTabComponent,
  ],
})
export class VerticalTabsModule {}
