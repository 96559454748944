import {Company} from '@cmp/shared/models/branding.model';
import {Environment} from './environment.model';

export const base: Environment = {
  company: Company.Gepard,
  name: 'base',
  production: false,
  apiBaseUrl: 'http://127.0.0.1:8000',
  loginUrl: ' https://login-beta.gpf.cz/',
  logoutUrl: ' https://login-beta.gpf.cz/?action=logout',
  tokenUrl: 'https://login-beta.gpf.cz/token.php',
  clientId: 'calculator',
  tokenUserIdField: 'email',
  removeOriginFromRedirectUrl: true,
  gtmId: '', // TODO
};
