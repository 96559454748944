import {FormlyExtension, FormlyFieldConfig} from '@ngx-formly/core';

export function onPopulate(field: FormlyFieldConfig) {
  if (field.wrappers && field.wrappers.indexOf('addons') !== -1) return;

  const to = field.templateOptions || {};
  const exp = field.expressionProperties || {};
  if (to.addonLeft || to.addonRight || exp['templateOptions.addonLeft'] || exp['templateOptions.addonRight']
    || to.tooltip || exp['templateOptions.tooltip']) {
    field.wrappers = ['addons', ...(field.wrappers || [])];
  }
}

export const addonsExtension: FormlyExtension = {onPopulate};
