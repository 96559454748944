import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ComparatorService, GetAllModelationsParams} from '@generated/controllers/Comparator';
import {
  ComparatorClient,
  Info,
  MaxCredit,
  MaxCreditResult,
  Modelation,
  ModelationResultCollection,
  ModelationsOutput,
  ReportFilter,
} from '@generated/model';
import {ConfirmDialogComponent, ConfirmDialogData} from '@lib/components';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, get, set} from 'lodash';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

const modelationsParamsDates: (keyof GetAllModelationsParams)[] = ['dateFrom', 'dateTo'];
const modelationsParamsAddTimes: [number, moment.unitOfTime.DurationConstructor][] = [[1, 'hours'], [25, 'hours']];
const modelationDates = ['mainApplicant.birthdate', 'coapplicant.birthdate'];

function datesForFE<T extends object>(o: T, dates: string[]): T {
  const newO = cloneDeep(o);
  dates.forEach(path => {
    const value = get(o, path);
    if (value) set(newO, path, moment(value).toDate());
  });
  return newO;
}

function datesForBE<T extends object>(
  o: T, dates: string[], withTime = false, addTimes?: [number, moment.unitOfTime.DurationConstructor][]): T {
  const newO = cloneDeep(o);
  dates.forEach((key, i) => {
    const value = get(o, key);
    const newValue = withTime
      ? moment(value).set('hours', 0).add(addTimes[i][0], addTimes[i][1]).format('YYYY-MM-DDThh:mm')
      : moment(value).format('YYYY-MM-DD');
    if (value) set(newO, key, newValue);
  });
  return newO;
}

function modelationsParamsForBE(params: GetAllModelationsParams): GetAllModelationsParams {
  return datesForBE(params, modelationsParamsDates, true, modelationsParamsAddTimes);
}

function modelationForFE(modelation: Modelation): Modelation {
  const feModelation = datesForFE(modelation, modelationDates);
  if (feModelation.estimations) {
    feModelation.estimations
      .sort((a, b) => {
        const aBankCode = a.bankCode;
        const bBankCode = b.bankCode;
        if (aBankCode < bBankCode) return -1;
        if (aBankCode > bBankCode) return 1;
        return 0;
      });
  }
  if (feModelation.individuals) feModelation.individuals.forEach(i => {i.interestRate *= 100; });
  return feModelation;
}

function modelationForBE(modelation: Modelation): Modelation {
  const beModelation = datesForBE(modelation, modelationDates);
  if (beModelation.individuals) beModelation.individuals.forEach(i => {i.interestRate /= 100; });
  return beModelation;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private service: ComparatorService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  loadModelations$(params: GetAllModelationsParams = {}): Observable<ModelationsOutput> {
    return this.service
      .getAllModelations(modelationsParamsForBE(params)).pipe(
        catchError((response: HttpErrorResponse) => {
          console.warn(response);
          return of({results: [], count: 0});
        }),
      );
  }

  loadModelation$(id: string | number): Observable<Modelation> {
    return this.service
      .loadModelationWithSepcifiedId({id: id.toString()}).pipe(
        map(m => modelationForFE(m)),
      );
  }

  createModelation$(client: ComparatorClient): Observable<Modelation> {
    return this.service
      .createModelationForSpecifiedClient({data: client}).pipe(
        map(m => modelationForFE(m)),
      );
  }

  saveModelation$(modelation: Modelation): Observable<Modelation> {
    const id = modelation.id.toString();
    return this.service
      .saveModelationWithSepcifiedId({id, data: modelationForBE(modelation)});
  }

  deleteModelation$(id: string | number, isFrozen = false): Observable<boolean> {
    const translationKey = `DIALOG.DELETE${isFrozen ? '_FROZEN' : ''}_MODELATION`;
    const dialogData: ConfirmDialogData = {
      title: this.translate.instant(`${translationKey}.TITLE`),
      text: this.translate.instant(`${translationKey}.TEXT`),
      confirmLabel: this.translate.instant(`${translationKey}.CONFIRM`),
      cancelLabel: this.translate.instant(`${translationKey}.CANCEL`),
    };
    return this.dialog.open(ConfirmDialogComponent, {data: dialogData}).afterClosed().pipe(
      mergeMap((doDelete: boolean) => doDelete ? this.service
        .deleteModelationWithSepcifiedId({id: id.toString()}).pipe(map(() => true))
        : of(false)),
    );
  }

  duplicateModelation$(id: string | number): Observable<Modelation> {
    return this.service.duplicateModelation({id: id.toString()}).pipe(
      map(m => modelationForFE(m)),
    );
  }

  loadBankIncomes$(modelation: Modelation) {
    return this.service
      .bankIncomes({id: modelation.id.toString(), data: modelationForBE(modelation)});
  }

  loadBankExpenditures$(modelation: Modelation) {
    return this.service
      .bankExpenditures({id: modelation.id.toString(), data: modelationForBE(modelation)});
  }

  computeResults$(modelation: Modelation): Observable<ModelationResultCollection> {
    return this.service
      .computeResults({id: modelation.id.toString(), data: modelationForBE(modelation)});
  }

  findMaxMortgage$(modelation: Modelation, maxCredit: MaxCredit): Observable<MaxCreditResult[]> {
    const data = {...modelationForBE(modelation), maxCredit};
    return this.service.findMaxCredit({id: data.id.toString(), data});
  }

  loadResults$(id: string | number): Observable<ModelationResultCollection> {
    return this.service.loadResults({id: id.toString()});
  }

  loadReportFilter$(id: string | number): Observable<ReportFilter> {
    return this.service
      .getsOrCreatesResultsSettingsForSpecifiedModelation({id: id.toString()});
  }

  saveReportFilter$(id: string | number, filters: ReportFilter): Observable<ReportFilter> {
    return this.service
      .updatesResultsSettingsForSpecifiedModelation({id: id.toString(), data: filters});
  }

  freezeModelation$(id: string | number, removedResultIds: number[] = []): Observable<Modelation> {
    return this.service.freezeModelation({id: id.toString(), data: {removedIds: removedResultIds}});
  }

  sendModelationViaEmail$(id: string | number, reportUrl: string) {
    return this.service.sendMail({id: id.toString(), report_url: reportUrl});
  }

  readAppInfo$(): Observable<Info> {
    return this.service
      .readInfo().pipe(
        catchError((response: HttpErrorResponse) => {
          console.warn(response);
          return of({versionText: this.translate.instant('HEADING.NO_APP_INFO'), newsTexts: []});
        }),
      );
  }
}
