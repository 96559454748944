import {Pipe, PipeTransform} from '@angular/core';
import {isNumber} from 'lodash';

@Pipe({
  name: 'years',
})
export class YearsPipe implements PipeTransform {
  transform(value: number): any {
    if (!isNumber(value)) return value;
    return getYearsPluralString(value);
  }
}

function getYearsPluralString(years: number, withoutNumber = false): string {
  const nrStr = withoutNumber ? '' : `${years} `;
  if (years === 1) return nrStr + 'rok';
  if (years > 1 && years < 5) return nrStr + 'roky';
  return nrStr + 'let';
}
