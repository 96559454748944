import {InjectionToken} from '@angular/core';

export interface CommonEnvironment {
  name: string;
  sentryDataSourceName?: string;
  gtmId: string;
  production: boolean;
  apiBaseUrl: string;
  loginUrl: string;
  logoutUrl: string;
  tokenUrl: string;
  clientId: string;
  tokenUserIdField: string;
  clientSecret?: string;
  resourceAccess?: string;
  removeOriginFromRedirectUrl?: boolean;
  YBugProjectId?: string;
  YBugTitle?: string;
}
export const environmentToken = new InjectionToken<CommonEnvironment>('ENVIRONMENT');
