import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModelationsState} from './modelations.state';

export const featureName = 'modelations';

export const selectState = createFeatureSelector<ModelationsState>(featureName);

export const selectModelations = createSelector(selectState, state => state.modelations);

export const selectParams = createSelector(selectState, state => state.params);

export const selectTotalCount = createSelector(selectState, state => state.totalCount);
