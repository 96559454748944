import {Inject, Injectable} from '@angular/core';

type GoogleTagManagerWindow = Window & {dataLayer: any[]};

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(
    @Inject('windowObject') private window: any,
    @Inject('gtmId') private gtmId: string,
  ) {
    if (!this.gtmId) return;
    (this.window as unknown as GoogleTagManagerWindow).dataLayer = [{
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    }];
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.insertBefore(script, document.head.firstChild);
  }

  setUser(userId?: string) {
    if (!this.gtmId) return;
    this.addDataLayer({userId});
  }

  addDataLayer(item: any) {
    (this.window as unknown as GoogleTagManagerWindow).dataLayer.push(item);
  }
}
