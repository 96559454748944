import {CurrencyPipe, getCurrencySymbol} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/material';
import {TranslateService} from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'formly-mat-input',
  templateUrl: './mat-label-with-value.component.html',
  styleUrls: ['./mat-label-with-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatLabelWithValueComponent extends FieldType implements OnInit {
  @ViewChild(MatInput, {static: false} as any) formFieldControl!: MatInput;

  constructor(
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    private cd: ChangeDetectorRef,
    ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(() => this.cd.detectChanges());
  }


  get label() {
    return this.translate.instant(this.to.label);
  }

  get formattedValue() {
    if (this.to.type === 'currency') {
      return this.currencyPipe.transform(this.value, 'CZK', '', '1.0-0');
    }
    return this.value;
  }

  get suffix() {
    if (this.to.type === 'currency') {
      return getCurrencySymbol( 'CZK', 'narrow');
    }
    return null;
  }
}
