import {AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-mat-addons-wrapper',
  template: `
  <ng-template *ngIf="to.addonLeft" #matPrefix>
    <span [ngClass]="to.addonLeft.class">
      <mat-icon *ngIf="to.addonLeft.icon" class="addon addon--prefix">{{to.addonLeft.icon}}</mat-icon>
      <mat-icon *ngIf="to.addonLeft.svgIcon" class="addon addon--prefix" [svgIcon]="to.addonLeft.svgIcon"></mat-icon>
      <span *ngIf="to.addonLeft.text">{{to.addonLeft.text}}</span>
    </span>
  </ng-template>

  <div class="field-wrapper">
    <ng-container #fieldComponent></ng-container>
  </div>

  <ng-template *ngIf="to.addonRight || to.tooltip" #matSuffix>
    <div class="addons">
      <span *ngIf="to.addonRight?.text">{{to.addonRight.text}}</span>
      <mat-icon *ngIf="to.addonRight?.icon" class="addon addon--suffix">{{to.addonRight.icon}}</mat-icon>
      <mat-icon *ngIf="to.addonRight?.svgIcon" class="addon addon--suffix" [svgIcon]="to.addonRight.svgIcon"></mat-icon>
      <!-- <mat-icon class="addon addon--suffix">home</mat-icon> -->
      <shd-tooltip *ngIf="to.tooltip" class="field-tooltip" matIcon="info" direction="left"
        width="14rem" textAlign="left" [stopClickPropagation]="true">
        {{to.tooltip}}
      </shd-tooltip>
    </div>
  </ng-template>

  `,
  styles: [`
    :host {
      display: flex;
      align-items: baseline;
    }

    .field-wrapper {
      flex-grow: 1;
      max-width: 100%;
    }

    .addons {
      display: inline-flex;
      align-items: center;
    }

    .addon {
      color: rgba(0, 0, 0, 0.54);
    }

    .addon--prefix {
      margin-right: 0.25rem;
    }

    .addon--suffix:not(:first-child) {
      margin-left: 0.5rem;
    }

    .field-tooltip {
      margin: -0.5rem 0 -0.5rem 0.25rem;
    }

    .field-tooltip ::ng-deep .custom-tooltip__toggle {
      width: 1.2rem;
      height: 1.2rem;
    }

    .field-wrapper ::ng-deep .mat-form-field-type-checkbox .field-tooltip .tooltip__toggle-icon svg {
      margin-bottom: 0.5rem;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatAddonsWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('matPrefix') matPrefix: TemplateRef<any>;
  @ViewChild('matSuffix') matSuffix: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.matPrefix) {
      Promise.resolve().then(() => this.setPrefix());
    }

    if (this.matSuffix) {
      Promise.resolve().then(() => this.setSuffix());
    }
  }

  private setPrefix() {
    this.to.prefix = this.matPrefix;
    (this.options as any)._markForCheck(this.field);
  }

  private setSuffix() {
    this.to.suffix = this.matSuffix;
    (this.options as any)._markForCheck(this.field);
  }
}
