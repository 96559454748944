import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {FieldType} from '@ngx-formly/material';

@Component({
  selector: 'formly-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatInputComponent extends FieldType {
  @ViewChild(MatInput, {static: false} as any) formFieldControl!: MatInput;

  get type() {
    if (this.to.type === 'number' && this.to.mask) return 'text';
    return this.to.type || 'text';
  }

  onInput(event: InputEvent) {
    if (typeof this.to.input === 'function') this.to.input(this.field, event);
  }
}
