import {ErrorHandler, Inject, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {CaptureConsole} from '@sentry/integrations';
import {CommonEnvironment, environmentToken} from '../models/environment.model';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject(environmentToken) environmentConfig: CommonEnvironment) {
    Sentry.init({
      dsn: environmentConfig.sentryDataSourceName,
      environment: environmentConfig.name,
      integrations: [
        new CaptureConsole({levels: ['error']}),
        new Sentry.Integrations.Breadcrumbs({dom: true}),
      ],
    });
  }

  handleError(error: Error | any) {
    Sentry.captureException(error.originalError || error);
  }
}
