import {Injectable} from '@angular/core';
import {ResourceLoaderService} from '@lib/services/resource-loader.service';

interface WindowYBug extends Window {
  ybug_settings?: any;
}

@Injectable({
  providedIn: 'root',
})
export class YBugService {
  private YBugLoaded: Promise<void>;
  private projectId: string;
  private title: string;

  constructor(private scriptLoaderService: ResourceLoaderService) {}

  initialize(projectId: string, title: string) {
    this.projectId = projectId;
    this.title = title;
  }

  async configure(token: any) {
    if (!this.projectId) return;

    try {
      (window as WindowYBug).ybug_settings = {
        id: this.projectId,
        user: token,
        feedback: {
          email: token.email,
          name: token.name,
        },
      };

      if (this.title) {
        (window as WindowYBug).ybug_settings.translate = {
          'launcherButton.Title': this.title,
          'feedbackTypeForm.Heading': this.title,
          'feedbackForm.Heading': this.title,
        };
      }

      await this.load();
    } catch (error) {
      console.error('Unable to load or configure YBug:', error);
    }
  }

  private load(): Promise<void> {
    if (this.YBugLoaded) return this.YBugLoaded;

    this.YBugLoaded = this.scriptLoaderService.loadScript({
      src: `https://widget.ybug.io/button/${(window as WindowYBug).ybug_settings.id}.js`,
      async: true,
    });

    return this.YBugLoaded;
  }
}
