import {BankCode} from '@cmp/shared';
import {BankExpenditures, BankIncomes, Modelation} from '@generated/model';
import {EditorStatus} from '../editor.model';

export interface EditorState {
  modelation: Modelation;
  ui: EditorUi;
  statusInfo: EditorStatusInfo;
  bankIncomes: BankIncomes[];
  bankExpenditures: BankExpenditures[];
}

export interface EditorUi {
  ltv?: string;
  repayment?: number;
  domiciliation?: boolean;
  parentalContributions?: boolean;
  alimony?: boolean;
  mainApplicantBusinessRentIncome?: boolean;
  coapplicant?: boolean;
  coapplicantBusinessRentIncome?: boolean;
  banksWithoutMatchingRiskInsurance: string[];
  activeBank: BankCode;
}

export interface EditorStatusInfo {
  status: EditorStatus;
  message?: string;
}

export const initialEditorState: EditorState = {
  modelation: null,
  ui: null,
  statusInfo: {status: null},
  bankIncomes: null,
  bankExpenditures: null,
};
