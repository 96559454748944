import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Option} from '@lib/models';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyFieldMultiCheckbox} from '@ngx-formly/material/multicheckbox/';
import {mapValues} from 'lodash';
import {combineLatest, of} from 'rxjs';
import {startWith} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'formly-field-mat-multicheckbox',
  template: `
    <ng-container *ngIf="to.showSelectAll">
      <mat-checkbox *ngIf="to.options | formlySelectOptions:field | async as options"
        class="select-all"
        [formControl]="selectAllControl"
        [disabled]="to.disabled"
        [indeterminate]="selectAllControl.value === null"
        (change)="onSelectOptions($event.checked ? options: [])">
          {{to.label}}
      </mat-checkbox>

      <mat-divider class="mat-divider--dense mx-0 mb-2"></mat-divider>
    </ng-container>

    <div class=" options"
      [style.display]="to.optionColumns ? 'grid' : 'block'"
      [style.grid-template-columns]="optionsGridColumns"
      data-field-type="matMulticheckbox">
      <div *ngFor="let option of to.options | formlySelectOptions:field | async as options; let i = index;"
          [class]="to.optionClass || ''">
        <mat-checkbox
          [id]="id + '_' + i"
          [formlyAttributes]="fieldWithoutOnChange"
          [tabindex]="to.tabindex || 0"
          [color]="to.color"
          [labelPosition]="to.labelPosition"
          [checked]="isChecked(option)"
          [disabled]="to.disabled || option.disabled"
          (change)="onChange(option.value, $event.checked)"
          [attr.data-value]="option.value">
            <span *ngIf="option.label" class="label-text">{{option.label}}</span>

            <ng-container *ngIf="to.optionIcons && to.optionIcons[option.value]">
              <mat-icon [svgIcon]="to.optionIcons[option.value]"></mat-icon>
              <span style="display: inline-block; width: 0; height:0; overflow: hidden;">&#8288;</span>
            </ng-container>
        </mat-checkbox>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatMultiCheckboxComponent extends FormlyFieldMultiCheckbox implements OnInit, OnDestroy {
  fieldWithoutOnChange: FormlyFieldConfig;
  selectAllControl = new FormControl();

  get optionsGridColumns() {
    return this.to.optionColumns ? Array(this.to.optionColumns).fill('1fr').join(' ') : null;
  }

  ngOnInit() {
    const options$ = Array.isArray(this.to.options) ? of(this.to.options) : this.to.options;
    combineLatest(
      this.formControl.valueChanges.pipe(startWith(null)),
      options$,
    ).pipe(
      untilDestroyed(this),
    ).subscribe(([_value, options]) => {
      this.updateSelectAll(options);
    });

    this.fieldWithoutOnChange = {
      ...this.field,
      templateOptions: {
        ...this.field.templateOptions,
        change: null,
      },
    };
  }

  ngOnDestroy() {}

  onSelectOptions(options: Option[]) {
    const newValue = this.to.type === 'array'
      ? options.map(o => o.value)
      : mapValues(this.value, (_selected, option) => options.find(o => o.value === option) ? true : false);
    this.formControl.patchValue(newValue);
  }

  onChange(value: any, checked: boolean) {
    super.onChange(value, checked);
    if (typeof this.to.change === 'function') this.to.change(this.field, this.formControl.value);
  }

  private updateSelectAll(options: Option[]) {
    const totalCount = (options || []).filter(o => !o.disabled).length;
    const selectedCount = this.to.type === 'array'
      ? (this.value || {}).length
      : Object.entries(this.value || {}).filter(([_option, selected]) => selected).length;
    const allSelected = selectedCount === totalCount ? true : (selectedCount > 0 ? null : false);
    this.selectAllControl.patchValue(allSelected);
  }
}
