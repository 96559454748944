/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {GetAllModelationsParams} from '@generated/controllers/Comparator';
import {BankExpenditures, BankIncomes, ComparatorClient, Modelation, ModelationResultCollection, ModelationsOutput, ReportFilter} from '@generated/model';
import {select, Store} from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import {catchError, filter, first, map, mergeMap, skip, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EditorActions, fromEditor} from '../../modules/editor/store';
import {ModelationsActions} from '../../modules/modelations/store';
import {fromReport, ReportActions} from '../../modules/report/store';
import {AppState} from '../../store';
import {ApiService} from './api.service';
/* eslint-enable max-len */

@Injectable({
  providedIn: 'root',
})
export class StoreService {

  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
  ) {}

  loadModelationsIntoState$(params?: GetAllModelationsParams): Observable<ModelationsOutput> {
    return this.apiService.loadModelations$(params).pipe(
      tap(({results, count}) => {
        this.store.dispatch(ModelationsActions.SetModelations(results));
        this.store.dispatch(ModelationsActions.SetTotalCount(count));
      }),
    );
  }

  createModelationAndLoadIntoState$(client: ComparatorClient) {
    return this.setModelationIntoState$(this.apiService.createModelation$(client));
  }

  loadModelationIntoState$(id: string | number): Observable<Modelation> {
    return this.setModelationIntoState$(this.apiService.loadModelation$(id));
  }

  duplicateModelationAndLoadIntoState$(id: string | number, fromState = false) {
    return (fromState
      ? this.store.pipe(select(fromEditor.selectModelation), first(), map(m => m.id))
      : of(id)
    ).pipe(
      switchMap(id2 => this.setModelationIntoState$(this.apiService.duplicateModelation$(id2))),
    );
  }

  autoSaveModelation$(): Observable<Modelation> {
    return this.store.pipe(
      select(fromEditor.selectModelation),
      withLatestFrom(this.store.pipe(select(fromEditor.selectStatus))),
      filter(([m, s]) => s === 'unsaved' && m && m.id != null),
      tap(() => this.store.dispatch(EditorActions.SetStatusInfo({status: 'saving'}))),
      switchMap(([modelation, _]) => this.apiService.saveModelation$(modelation)),
      tap(() => this.store.dispatch(EditorActions.SetStatusInfo({status: 'saved'}))),
      catchError((errorResponse, autoSaveModelation$) => {
        console.error(errorResponse);
        this.store.dispatch(EditorActions.SetStatusInfo({
          status: 'error', message: JSON.stringify(errorResponse.error),
        }));
        return autoSaveModelation$;
      }),
    );
  }

  loadBankIncomesIntoState$(): Observable<BankIncomes[]> {
    this.store.dispatch(EditorActions.SetBankIncomes(null));
    return this.store.pipe(
      select(fromEditor.selectModelation),
      first(),
      switchMap(modelation => this.apiService.loadBankIncomes$(modelation)),
      tap(bankIncomes => {
        this.store.dispatch(EditorActions.SetBankIncomes(bankIncomes));
      }),
    );
  }

  loadBankExpendituresIntoState$(): Observable<BankExpenditures[]> {
    this.store.dispatch(EditorActions.SetBankExpenditures(null));
    return this.store.pipe(
      select(fromEditor.selectModelation),
      first(),
      switchMap(modelation => this.apiService.loadBankExpenditures$(modelation)),
      tap(bankExpenditures => {
        this.store.dispatch(EditorActions.SetBankExpenditures(bankExpenditures));
      }),
    );
  }

  loadReportIntoState$(id: string | number, computeFromState = false): Observable<ModelationResultCollection> {
    this.loadReportFilterIntoState$(id).subscribe();

    const modelation$ = computeFromState
      ? this.store.pipe(select(fromEditor.selectModelation), first())
      : this.loadModelationIntoState$(id);
    return modelation$.pipe(
      tap(),
      switchMap(modelation => {
        if (modelation.isFrozen) return this.apiService.loadResults$(modelation.id);
        if (computeFromState) return this.apiService.computeResults$(modelation);
        else return throwError('no report available');
      }),
      tap(results => {
        this.store.dispatch(ReportActions.SetResults(results));
      }),
    );
  }

  autoSaveReportFilter$(skipCount = 0): Observable<ReportFilter> {
    return this.store.pipe(
      select(fromReport.selectFilter),
      withLatestFrom(this.store.select(fromEditor.selectModelation)),
      skip(skipCount),
      filter(([f, m]) => f && m && m.id != null && !m.isFrozen),
      switchMap(([filters, {id}]) => this.apiService.saveReportFilter$(id, filters)),
    );
  }

  freezeModelationAndUpdateState$(): Observable<Modelation> {
    return this.store.pipe(
      select(fromEditor.selectModelation),
      withLatestFrom(this.store.select(fromReport.selectResults)),
      first(),
      mergeMap(([m, rs]) => this.apiService.freezeModelation$(
        m.id,
        rs.filter(r => r.isRemoved).map(r => r.id),
      )),
      tap(modelation => {
        this.store.dispatch(EditorActions.SetModelation(modelation));
      }),
    );
  }

  private setModelationIntoState$(modelation$: Observable<Modelation>) {
    return modelation$.pipe(
      tap(modelation => {
        this.store.dispatch(EditorActions.SetModelation(modelation));
      }),
    );
  }

  private loadReportFilterIntoState$(id: string | number): Observable<ReportFilter> {
    return this.apiService.loadReportFilter$(id).pipe(
      tap((filters: ReportFilter) => {
        this.store.dispatch(ReportActions.SetFilter(filters));
      }),
    );
  }
}
