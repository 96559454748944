import {TranslateService} from '@ngx-translate/core';
import {TranslatePluralPipe} from './pipes';

export function getFixationLabel(fixation: string, translate: TranslateService, pluralPipe: TranslatePluralPipe) {
  if (!fixation) return fixation;

  if (!isNaN(+fixation)) {
    return +fixation > 0
      ? pluralPipe.transform(+fixation, 'YEARS.')
      : translate.instant('FLOAT_FIXATION');
  }

  const fixations = fixation.split(', ').map(f => +f);
  if (fixations.length > 1) {
    const last = fixations.sort().pop();
    return `${fixations.join(', ')} ${translate.instant('AND')} ${pluralPipe.transform(last, 'YEARS.')}`;
  }

  return translate.instant(fixation.toUpperCase());
}
