import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

// This component is used for setting @page styles and other global print styles.
@Component({
  selector: 'cmp-print-preferences',
  template: '',
  styleUrls: ['./print-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PrintPreferencesComponent {
}
