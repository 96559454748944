import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'cmp-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent {
  @Input() title: string;
  @Input() openIcon: string;
  @Input() closeIcon = 'close';
  @Input() opened = false;
  @Input() class = '';
  @Output() toggle = new EventEmitter<boolean>();

  @HostBinding('class') get classList() {
    return 'panel ' + (!this.opened ? 'panel--closed' : '') + (this.class || '');
  }
}
