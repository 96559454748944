import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {BrandingService} from '@cmp/shared';
import {GtmService, LoginService, setErrorLoggerAdvisor, YBugService} from '@lib/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent} from 'rxjs';
import {pairwise, startWith} from 'rxjs/operators';
import {environment} from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'mtg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;

  constructor(
    gtmService: GtmService,
    private loginService: LoginService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    titleService: Title,
    translate: TranslateService,
    router: Router,
    cdRef: ChangeDetectorRef,
    private branding: BrandingService,
    private renderer: Renderer2,
    yBugService: YBugService,
  ) {
    gtmService.setUser(this.loginService.getUserId());
    setErrorLoggerAdvisor(this.loginService.getUserBasicInformation());

    yBugService.initialize(environment.YBugProjectId, environment.YBugTitle);

    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons.svg'));
    // TODO load those in mortgage comparator core
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons-core.svg'));

    translate.get('SITE.TITLE').subscribe(title => {
      titleService.setTitle(title);
    });

    router.events.pipe(
      untilDestroyed(this),
    ).subscribe(event => {
      if (event instanceof NavigationStart) this.loading = true;
      else if ([NavigationEnd, NavigationCancel, NavigationError].some(T => event instanceof T)) {
        this.loading = false;
        if (event instanceof NavigationError) console.warn(event.error);
      }
    });

  }

  ngOnInit() {
    this.branding.theme$.pipe(
      startWith(null),
      pairwise(),
      untilDestroyed(this),
    ).subscribe(([oldTheme, newTheme]) => {
      this.renderer.removeClass(document.body, oldTheme);
      this.renderer.addClass(document.body, newTheme);
    });

    this.branding.favicon$.pipe(
      untilDestroyed(this),
    ).subscribe(favicon => {
      this.renderer.setAttribute(document.getElementById('favicon'), 'href', favicon);
    });

    this.setVhProperty();
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setVhProperty());
  }

  ngOnDestroy() {}

  private setVhProperty() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
  }
}
