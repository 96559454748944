import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CurrencyCZKPipe} from './currency-czk.pipe';
import {FilterPipe} from './filter.pipe';
import {GroupByPipe} from './group-by.pipe';
import {JsonSafePipe} from './json-safe.pipe';
import {OrderByPipe} from './order-by.pipe';
import {YearsPipe} from './years.pipe';

@NgModule({
  declarations: [
    CurrencyCZKPipe,
    FilterPipe,
    GroupByPipe,
    JsonSafePipe,
    OrderByPipe,
    YearsPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CurrencyCZKPipe,
    FilterPipe,
    GroupByPipe,
    JsonSafePipe,
    OrderByPipe,
    YearsPipe,
  ],
})
export class PipesModule {
}
