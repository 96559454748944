<button *ngIf="type !== 'primary'"
  mat-stroked-button
  class="toolbar-button"
  [disabled]="disabled">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>

<button *ngIf="type === 'primary'"
  mat-flat-button
  class="toolbar-button"
  color="primary"
  [disabled]="disabled">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>