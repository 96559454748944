import {Pipe, PipeTransform} from '@angular/core';
import {ListIterator, Many, NotVoid, orderBy} from 'lodash';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {

  transform(
    data: any[],
    iteratees?: Many<ListIterator<any, NotVoid>>,
    orders: Many<boolean | 'asc' | 'desc'> = 'asc',
  ): any {
    return orderBy(data, iteratees, orders);
  }
}
