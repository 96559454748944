<button mat-mini-fab
  class="panel__toggle"
  color="primary"
  (click)="toggle.emit(opened)">
  <mat-icon [svgIcon]="opened ? 'close' : 'info'"></mat-icon>
</button>

<div class="panel__content-wrapper content content--dense">
  <h3 class="panel__title">{{title}}</h3>
  <div class="panel__content">
    <ng-content></ng-content>
  </div>
</div>