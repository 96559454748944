import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {calculatePeriodicPayments, Payment} from '../../utils';

@Component({
  selector: 'shd-loan-chart',
  templateUrl: './loan-chart.component.html',
  styleUrls: ['./loan-chart.component.scss'],
})
export class LoanChartComponent implements OnChanges {
  /* either input loan, interest and loanTerm */
  @Input() loan?: number;
  @Input() interest?: number;
  @Input() loanTerm?: number; // in months
  /* or payments */
  @Input() payments?: Payment[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.payments) return;
    if (!this.payments) this.payments = [];
    const payments = calculatePeriodicPayments(this.loan, this.interest / 12, this.loanTerm)
      .filter((_, index) => index % 12 === 0);
    if (this.payments.length === payments.length) {
      // mutate this.lines to have animation of the lines
      for (let i = 0; i < payments.length; i++) {
        this.payments[i].interest = payments[i].interest;
        this.payments[i].amortization = payments[i].amortization;
      }
    } else {
      this.payments = payments;
    }
  }
}
