<input matInput
  [id]="id"
  [type]="type"
  [readonly]="to.readonly"
  [required]="to.required"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [tabindex]="to.tabindex"
  [placeholder]="to.placeholder"
  [imask]="to.mask"
  [unmask]="to.mask ? 'typed' : null"
  (input)="onInput($event)" />