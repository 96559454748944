import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ToolbarButtonComponent} from './toolbar-button/toolbar-button.component';
import {ToolbarComponent} from './toolbar.component';

@NgModule({
  declarations: [
    ToolbarComponent,
    ToolbarButtonComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    // Material
    MatToolbarModule,
    MatButtonModule,
  ],
  exports: [
    ToolbarComponent,
    ToolbarButtonComponent,
  ],
})
export class ToolbarModule {}
