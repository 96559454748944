import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'separateSymbol',
})
export class SeparateSymbolPipe implements PipeTransform {

  constructor() {}

  transform(value: string): string {
    if (!value) return;
    const matcher = /\s\D+/g;
    const number = value.replace(matcher, '');
    return `<span class="number">${number}</span><span class="unit">${value.match(matcher)[0]}</span>`;
  }
}
