<div *ngIf="payments"
  class="chart">
  <div class="header-bar">
    <div class="interest-label">Úrok</div>
    <div class="amortization-label">Úmor</div>
  </div>
  <div class="line"
    *ngFor="let payment of payments; let index = index">
    <div class="year">{{index + 1}}. rok</div>
    <div class="bar">
      <div class="interest"
        [style.flex-grow]="payment.interest"></div>
      <div class="amortization"
        [style.flex-grow]="payment.amortization"></div>
      <div class="interest-label">{{payment.interest | currencyCZK}}</div>
      <div class="amortization-label">{{payment.amortization | currencyCZK}}</div>
    </div>
  </div>
</div>