import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class PrintInterceptor implements HttpInterceptor {
  private pathMatcher = new RegExp('/modelations/\\d+/report');

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = new URL(location.href);
    if (url.pathname.match(this.pathMatcher) && url.searchParams.has('print')) {
      return next.handle(req.clone({headers: req.headers.set('X-Print', 'true')}));
    } else {
      return next.handle(req);
    }
  }
}
