import {Injectable} from '@angular/core';

export interface ScriptDefinition {
  src: string;
  async?: boolean;
}

export interface StyleDefinition {
  src: string;
  media?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceLoaderService {

  loadScript(definition: ScriptDefinition): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = definition.src;
      script.async = definition.async ?? false;
      script.onload = () => resolve();
      script.onerror = (error: any) => reject(error);
      document.head.appendChild(script);
    });
  }

  loadStyle(definition: StyleDefinition): Promise<void> {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = definition.src;
      if (definition.media) link.media = definition.media;
      link.onload = () => resolve();
      link.onerror = (error: any) => reject(error);
      document.head.appendChild(link);
    });
  }
}
