/*
  Based on FormlyDatepickerTypeComponent from @ngx-formly/material/datepicker, could not be extended as it is not
  exported.
  https://raw.githubusercontent.com/ngx-formly/ngx-formly/v5.9.3/src/material/datepicker/src/datepicker.type.ts

  Adapted to support datepicker toggle besides suffix.

  CODE CHANGES:
  - renamed from 'FormlyDatepickerTypeComponent' to 'FormlyMatDatepickerComponent'
  - changed selector from 'formly-field-mat-datepicker' to 'formly-mat-datepicker'
  - removed line 52: datepickerTogglePosition no longer configurable, always suffix
  - changed line 82 (67 in original): use hidden prop _matDatePickerToggle instead of _matSuffix
*/

import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {MatDatepickerInput} from '@angular/material/datepicker';
import {MatInput} from '@angular/material/input';
import {ɵdefineHiddenProp as defineHiddenProp} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material/form-field';
import * as moment from 'moment';

const adultButNotTooOld = (date: moment.Moment): ValidationErrors => {
  if (!date) return null;
  const minBirthDate = moment().subtract(18, 'year').valueOf();
  const maxBirthDate = moment().subtract(70, 'year').valueOf();
  return date.valueOf() > minBirthDate ? {lessThanMin: true} : date.valueOf() < maxBirthDate ? {moreThanMax: true} : null;
};

@Component({
  selector: 'formly-mat-datepicker',
  template: `
    <input
      #input
      matInput
      [id]="id"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [matDatepicker]="picker"
      [matDatepickerFilter]="to.datepickerOptions.filter"
      [max]="to.datepickerOptions.max"
      [min]="to.datepickerOptions.min"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabindex]="to.tabindex"
      [readonly]="to.readonly"
      [required]="to.required"
      (change)="onChange($event)"
      (dateInput)="to.datepickerOptions.dateInput(field, $event)"
      (dateChange)="to.datepickerOptions.dateChange(field, $event)">
    <ng-template #datepickerToggle>
      <mat-datepicker-toggle *ngIf="to.datepickerOptions.showToggle" [for]="picker"></mat-datepicker-toggle>
    </ng-template>
    <mat-datepicker #picker
      [color]="to.color"
      [dateClass]="to.datepickerOptions.dateClass"
      [disabled]="to.datepickerOptions.disabled"
      [opened]="to.datepickerOptions.opened"
      [panelClass]="to.datepickerOptions.panelClass"
      [startAt]="to.datepickerOptions.startAt"
      [startView]="to.datepickerOptions.startView"
      [touchUi]="to.datepickerOptions.touchUi"
      (monthSelected)="to.datepickerOptions.monthSelected(field, $event, picker)"
      (yearSelected)="to.datepickerOptions.yearSelected(field, $event, picker)"
    >
    </mat-datepicker>
  `,
})
export class FormlyMatDatepickerComponent extends FieldType implements AfterViewInit {
  @ViewChild(MatInput, {static: true} as any) formFieldControl!: MatInput;
  @ViewChild('input') datepickerInput!: MatDatepickerInput<any>;
  @ViewChild('datepickerToggle') datepickerToggle!: TemplateRef<any>;

  defaultOptions = {
    templateOptions: {
      datepickerOptions: {
        startView: 'month',
        showToggle: true,
        dateInput: () => {},
        dateChange: () => {},
        monthSelected: () => {},
        yearSelected: () => {},
      },
    },
  };

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.datepickerInput as any)._formField = this.formField;

    setTimeout(() => {
      defineHiddenProp(this.field, '_matDatepickerToggle', this.datepickerToggle);
      (this.options as any)._markForCheck(this.field);
    });

    if (this.to.adultCheck) {
      this.formControl.valueChanges.subscribe(date => {
        this.formControl.setErrors(adultButNotTooOld(date));
        this.formControl.markAsTouched();
      });
    }
  }

  onChange(event: any) {
    if (this.to.smartAge && event.target.value >= 18 && event.target.value <= 70) {
      this.formControl.patchValue(moment().startOf('year').subtract(event.target.value, 'year'));
    }
  }
}
