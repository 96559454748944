import {base} from './environment.base.gepard';
import {Environment} from './environment.model';

export const environment: Environment = {
  ...base,
  name: 'dev',
  apiBaseUrl: '',
  sentryDataSourceName: 'https://d45aa2648435424eba0f4685042420bc@error.kapitol.cz/4',
  YBugProjectId: '86t16k0ys69jvwz7ctsy',
};
