import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormlyFieldRadio} from '@ngx-formly/material/radio/';

@Component({
  selector: 'formly-mat-radio',
  template: `
    <mat-radio-group
      [ngClass]="to.radioGroupClass"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabindex]="to.tabindex">
      <mat-radio-button *ngFor="let option of to.options | formlySelectOptions:field | async; let i = index;"
        [id]="id + '_' + i"
        [color]="to.color"
        [labelPosition]="to.labelPosition"
        [value]="option.value"
        [ngClass]="to.radioButtonClass">
        <div class="label-text">{{option.label}}</div>

        <div *ngIf="to.optionIcons && to.optionIcons[i]"
          class="label-icons">
          <div *ngFor="let icon of to.optionIcons[i]"
            class="icon icon--chipped">
            <mat-icon [svgIcon]="icon"></mat-icon>
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  `,
  styles: [`
    .label-text {
      display: inline-block;
      min-width: 12ch;
    }

    .label-icons {
      display: inline-block;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatRadioComponent extends FormlyFieldRadio {}
