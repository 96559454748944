import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FieldType} from '@ngx-formly/material';

@Component({
  selector: 'formly-mat-button',
  template: `
    <button mat-stroked-button
      type="button"
      [formlyAttributes]="field"
      [disabled]="to.disabled"
      (click)="to.click(field, $event)"
      [class.icon-button]="to.icon && !to.label"
      [attr.data-cy]="to.addCy">
      <mat-icon *ngIf="to.icon">{{to.icon}}</mat-icon>
      {{to.label}}
    </button>
  `,
  styles: [`
    :host {
      display: block;
      margin-bottom: 1.25em;
    }
    .icon-button {
      width: 2rem;
      min-width: 2rem;
      padding: 0;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatButtonComponent extends FieldType {}
