import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-toggle-button-group',
  template: `
    <div class="toggles">
      <mat-button-toggle-group [ngClass]="'mb-3 ' + to.buttonGroupClass" [formControl]="formControl" [multiple]="to.multiple">
        <mat-button-toggle *ngFor="let option of to.options" [value]="option.value" (change)="toggleChange($event)"
          [formlyAttributes]="field"
          [attr.data-value]="option.value"
          [ngClass]="to.buttonClass">
          {{option.label}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  `,
  styles: [`:host ::ng-deep .mat-button-toggle-label-content {line-height: 34px}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatToggleButtonGroupComponent extends FieldType {
  private oldValue: any;

  toggleChange(event: MatButtonToggleChange) {
    // to un-toggle button
    if (event.value === this.oldValue && !this.to.multiple) {
      this.formControl.patchValue(null);
      this.oldValue = null;
      return;
    }

    this.oldValue = event.value;
    if (typeof this.to.change === 'function') this.to.change(this.field, event);
  }
}
