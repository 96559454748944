import {BankExpenditures, BankIncomes, Modelation} from '@generated/model';
import {ofType, unionize, UnionOf} from 'unionize';
import {EditorModel} from '../editor.model';
import {EditorStatusInfo} from './editor.state';

export const EditorActions = unionize({
  SetModelation: ofType<Modelation>(),
  UpdateModel: ofType<EditorModel & {isInvalid?: boolean}>(),
  SetStatusInfo: ofType<EditorStatusInfo>(),
  SetBankIncomes: ofType<BankIncomes[]>(),
  SetBankExpenditures: ofType<BankExpenditures[]>(),
}, {tag: 'type', value: 'payload'});

export type EditorAction = UnionOf<typeof EditorActions>;
