import {ModelationResultCollection, ReportFilter} from '@generated/model';

export interface ReportState {
  filter: ReportFilter;
  results: ModelationResultCollection;
}

export const initialReportState: ReportState = {
  filter: null,
  results: null,
};
