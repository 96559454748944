/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * localhost:8000/api
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface BankExpendituresParams {
  data: __model.Modelation;
  id: string;
}

export interface BankIncomesParams {
  data: __model.Modelation;
  id: string;
}

export interface ComputeExternalBonitaModelationParams {
  data: __model.ExternalBonitaModelation;
}

export interface ComputeExternalModelationParams {
  data: __model.ExternalModelation;
}

export interface ComputeResultsParams {
  data: __model.Modelation;
  id: string;
}

export interface DuplicateModelationParams {
  id: string;
}

export interface FindMaxCreditParams {
  data: __model.MaxCreditEndpoint;
  id: string;
}

export interface FreezeModelationParams {
  data: __model.RemovedModelations;
  id: string;
}

export interface GeneratePdfParams {
  report_url: string;
  id: string;
}

export interface GetDetailedInformationParams {
  /** format: date-time */
  date_from: string;
  /** format: date-time */
  date_to: string;
  advisor?: string;
  /** default: day */
  granularity?: GranularityGetDetailedInformationParamsEnum;
}

export type GranularityGetDetailedInformationParamsEnum =
  'day' |
  'week' |
  'month';

export interface GetPdfParams {
  id: string;
}

export interface GetAllModelationsParams {
  text?: string;
  /** format: date-time */
  dateFrom?: string;
  /** format: date-time */
  dateTo?: string;
  offset?: number;
  /** default: 20 */
  limit?: number;
}

export interface CreateModelationForSpecifiedClientParams {
  data: __model.ComparatorClient;
}

export interface LoadResultsParams {
  id: string;
}

export interface LoadModelationWithSepcifiedIdParams {
  id: string;
}

export interface SaveModelationWithSepcifiedIdParams {
  data: __model.Modelation;
  id: string;
}

export interface DeleteModelationWithSepcifiedIdParams {
  id: string;
}

export interface SaveTheNewNoticeParams {
  data: __model.Notice;
}

export interface GetsOrCreatesResultsSettingsForSpecifiedModelationParams {
  id: string;
}

export interface UpdatesResultsSettingsForSpecifiedModelationParams {
  data: __model.ReportFilter;
  id: string;
}

export interface PaymentCalendarParams {
  data: __model.PaymentCalendarInput;
}

export interface SendMailParams {
  report_url: string;
  id: string;
}

export interface UploadExcelFileParams {
  data: __model.UploadFiles;
}

@Injectable()
export class ComparatorService {
  constructor(private http: HttpClient) {}

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/createPaymentCalendar */
  activateNewGraphFile(): Observable<void> {
    return this.http.post<void>(`/api/comparator/activate-new-graph-file/`, {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeExpedituresForBanks */
  bankExpenditures(params: BankExpendituresParams): Observable<__model.BankExpenditures[]> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.BankExpenditures[]>(`/api/comparator/bank-expenditures/${pathParams.id}`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeIncomesForBanks */
  bankIncomes(params: BankIncomesParams): Observable<__model.BankIncomes[]> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.BankIncomes[]>(`/api/comparator/bank-incomes/${pathParams.id}`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/checkLastGraphFileState */
  checkGraphFileState(): Observable<__model.Status> {
    return this.http.get<__model.Status>(`/api/comparator/check-graph-file-state/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeBonitaResultsFromExternalSource */
  computeExternalBonitaModelation(params: ComputeExternalBonitaModelationParams): Observable<__model.ExternalBonitaResult> {
    const bodyParams = params.data;

    return this.http.post<__model.ExternalBonitaResult>(`/api/comparator/compute-external-bonita-modelation/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeResultsFromExternalSource */
  computeExternalModelation(params: ComputeExternalModelationParams): Observable<__model.ExternalResult[]> {
    const bodyParams = params.data;

    return this.http.post<__model.ExternalResult[]>(`/api/comparator/compute-external-modelation/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeResultsFromMortgageComparator */
  computeResults(params: ComputeResultsParams): Observable<__model.ModelationResultCollection> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.ModelationResultCollection>(`/api/comparator/compute-results/${pathParams.id}`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/duplicateModelation */
  duplicateModelation(params: DuplicateModelationParams): Observable<__model.Modelation> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.Modelation>(`/api/comparator/duplicate-modelation/${pathParams.id}/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/computeMaxCreditForAllBanks */
  findMaxCredit(params: FindMaxCreditParams): Observable<__model.MaxCreditResult[]> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.MaxCreditResult[]>(`/api/comparator/find-max-credit/${pathParams.id}`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/freezeModelation */
  freezeModelation(params: FreezeModelationParams): Observable<__model.Modelation> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.Modelation>(`/api/comparator/freeze-modelation/${pathParams.id}/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/generatePdf */
  generatePdf(params: GeneratePdfParams): Observable<void> {
    const queryParamBase = {
      report_url: params.report_url,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      id: params.id,
    };
    return this.http.post<void>(`/api/comparator/generate-pdf/${pathParams.id}/`, {}, {params: queryParams});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getAdminInformationAboutAdvisors */
  getCompleteInformation(): Observable<__model.AdvisorStatistics[]> {
    return this.http.get<__model.AdvisorStatistics[]>(`/api/comparator/get-complete-information/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getAdminInformationAboutModelations */
  getDetailedInformation(params: GetDetailedInformationParams): Observable<__model.DetailedComparatorAdminViewResult[]> {
    const queryParamBase = {
      date_from: params.date_from,
      date_to: params.date_to,
      advisor: params.advisor,
      granularity: params.granularity,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.DetailedComparatorAdminViewResult[]>(`/api/comparator/get-detailed-information/`, {params: queryParams});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getPdf */
  getPdf(params: GetPdfParams): Observable<void> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<void>(`/api/comparator/get-pdf/${pathParams.id}`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getAllModelations */
  getAllModelations(params: GetAllModelationsParams): Observable<__model.ModelationsOutput> {
    const queryParamBase = {
      text: params.text,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      offset: params.offset,
      limit: params.limit,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.ModelationsOutput>(`/api/comparator/list-create-modelation/`, {params: queryParams});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/createModelationForSpecifiedClient */
  createModelationForSpecifiedClient(params: CreateModelationForSpecifiedClientParams): Observable<__model.Modelation> {
    const bodyParams = params.data;

    return this.http.post<__model.Modelation>(`/api/comparator/list-create-modelation/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getFilterSettingsForSpecifiedModelation */
  loadResults(params: LoadResultsParams): Observable<__model.ModelationResultCollection> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.ModelationResultCollection>(`/api/comparator/load-results/${pathParams.id}`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/loadModelationWithSepcifiedId */
  loadModelationWithSepcifiedId(params: LoadModelationWithSepcifiedIdParams): Observable<__model.Modelation> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.Modelation>(`/api/comparator/load-save-delete-modelation/${pathParams.id}/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/saveModelationWithSepcifiedId */
  saveModelationWithSepcifiedId(params: SaveModelationWithSepcifiedIdParams): Observable<__model.Modelation> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.Modelation>(`/api/comparator/load-save-delete-modelation/${pathParams.id}/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/deleteModelationWithSepcifiedId */
  deleteModelationWithSepcifiedId(params: DeleteModelationWithSepcifiedIdParams): Observable<void> {
    const pathParams = {
      id: params.id,
    };
    return this.http.delete<void>(`/api/comparator/load-save-delete-modelation/${pathParams.id}/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/loadTheLastNotice */
  loadTheLastNotice(): Observable<__model.Notice> {
    return this.http.get<__model.Notice>(`/api/comparator/load-save-notice/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/saveTheNewNotice */
  saveTheNewNotice(params: SaveTheNewNoticeParams): Observable<__model.Notice> {
    const bodyParams = params.data;

    return this.http.post<__model.Notice>(`/api/comparator/load-save-notice/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/getsOrCreatesResultsSettingsForSpecifiedModelation */
  getsOrCreatesResultsSettingsForSpecifiedModelation(params: GetsOrCreatesResultsSettingsForSpecifiedModelationParams): Observable<__model.ReportFilter> {
    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.ReportFilter>(`/api/comparator/load-save-report-settings/${pathParams.id}/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/updatesResultsSettingsForSpecifiedModelation */
  updatesResultsSettingsForSpecifiedModelation(params: UpdatesResultsSettingsForSpecifiedModelationParams): Observable<__model.ReportFilter> {
    const bodyParams = params.data;

    const pathParams = {
      id: params.id,
    };
    return this.http.post<__model.ReportFilter>(`/api/comparator/load-save-report-settings/${pathParams.id}/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/createPaymentCalendar */
  paymentCalendar(params: PaymentCalendarParams): Observable<__model.Payment[]> {
    const bodyParams = params.data;

    return this.http.post<__model.Payment[]>(`/api/comparator/payment-calendar/`, bodyParams || {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/readInfoFromKoala */
  readInfo(): Observable<__model.Info> {
    return this.http.get<__model.Info>(`/api/comparator/read-info/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/readNoticeIfExists */
  readNotice(): Observable<__model.Notice> {
    return this.http.get<__model.Notice>(`/api/comparator/read-notice/`);
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/rejectNotice */
  rejectNotice(): Observable<void> {
    return this.http.post<void>(`/api/comparator/reject-notice/`, {});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/sendMailWithModelationsDescription */
  sendMail(params: SendMailParams): Observable<void> {
    const queryParamBase = {
      report_url: params.report_url,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      id: params.id,
    };
    return this.http.get<void>(`/api/comparator/send-mail/${pathParams.id}`, {params: queryParams});
  }

  /** http://localhost:8000/swagger/swagger-ui.html#!/comparator/uploadExcelFile */
  uploadExcelFile(params: UploadExcelFileParams): Observable<__model.UploadFiles> {
    const bodyParams = params.data;

    return this.http.post<__model.UploadFiles>(`/api/comparator/upload-excel-file/`, bodyParams || {});
  }
}
