import {CoApplicant, MainApplicant} from '@generated/model';
import {hasNonDefaultValues, updateObject} from '@lib/utils';
import {union} from 'lodash';
import {calculateLTVPercent} from '../editor.utils';
import {getBanksWithoutMatchingRiskInsurance, getSharedAreas} from '../risk-insurance.utils';
import {EditorAction, EditorActions} from './editor.actions';
import {EditorState, EditorStatusInfo, initialEditorState} from './editor.state';

export const editorProducer = (state: EditorState, action: EditorAction): EditorState => EditorActions.match(action, {
  SetModelation: modelation => {
    if (!modelation) return initialEditorState;
    const riskInsurancesSelectionMode = {
      ...modelation.riskInsurancesSelectionMode,
      areas: union(
        getSharedAreas(modelation.riskInsuranceConfigs),
        modelation.riskInsurancesSelectionMode.areas,
      ),
    };
    return {
      ...state,
      modelation: {
        ...modelation,
        riskInsurancesSelectionMode,
      },
      ui: {
        ...state.ui,
        ltv: calculateLTVPercent(modelation.credit.credit, modelation.credit.guarantee),
        domiciliation: modelation.mainTab.domiciliationAmount != null,
        parentalContributions: hasParentalContributions(modelation.mainApplicant),
        alimony: hasAlimony(modelation.mainApplicant),
        mainApplicantBusinessRentIncome: hasBusinessRentIncome(modelation.mainApplicant),
        coapplicant: hasNonDefaultValues(modelation.coapplicant),
        coapplicantBusinessRentIncome: hasBusinessRentIncome(modelation.coapplicant),
        banksWithoutMatchingRiskInsurance: getBanksWithoutMatchingRiskInsurance(
          modelation.riskInsuranceConfigs, riskInsurancesSelectionMode),
      },
      statusInfo: {status: 'saved'} as EditorStatusInfo,
    };
  },
  UpdateModel: ({modelation, ui, isInvalid = false}) => {
    // quick fix for domiciliation: implemented in editor.fields but imask changes value null to 0
    if (!ui.domiciliation) modelation.mainTab.domiciliationAmount = null;
    return {
      ...state,
      modelation: updateObject(state.modelation, modelation),
      ui: updateObject(state.ui, ui),
      statusInfo: !isInvalid ? {status: 'unsaved'} : state.statusInfo,
    };
  },
  SetStatusInfo: statusInfo => statusInfo.status !== 'saved' ||
    !state.statusInfo || state.statusInfo.status !== 'invalid' ? ({
      ...state,
      statusInfo,
    }) : state,
  SetBankIncomes: bankIncomes => ({...state, bankIncomes}),
  SetBankExpenditures: bankExpenditures => ({...state, bankExpenditures}),
  default: () => state,
});

export function editorReducer(state = initialEditorState, action: EditorAction) {
  return editorProducer(state, action);
  // return produce(editorProducer)(state, action);
}

function hasBusinessRentIncome(applicant: MainApplicant | CoApplicant) {
  return hasNonDefaultValues(applicant, [
    'flatRateValue', 'revenuesParagraphPar7', 'revenuesParagraphPar9', 'taxBase', 'revenuesCostsDifference',
    'partialTaxBasePar9', 'tax', 'partialTaxBasePar7', 'itemsDecreasingTaxBase', 'realCosts',
    'partialTaxBasePar7LastYear', 'taxCredit',
  ]);
}

function hasParentalContributions(applicant: MainApplicant) {
  return hasNonDefaultValues(applicant, ['parentalContribution', 'futureParentalContribution']);
}

function hasAlimony(applicant: MainApplicant) {
  return hasNonDefaultValues(applicant, ['alimonyFirstChild', 'alimonySecondChild', 'alimonyThirdChild']);
}
