import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'mat-info-field',
  templateUrl: './info-field.component.html',
  styleUrls: ['./info-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoFieldComponent {
  @Input() label: string;
  @Input() class = '';

  @HostBinding('class') get classList() {
    return 'info-field ' + (this.class || '');
  }
}
