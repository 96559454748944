import {I18nPluralPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translatePlural',
})
export class TranslatePluralPipe implements PipeTransform {

  constructor(private i18nPluralPipe: I18nPluralPipe, private translate: TranslateService) {}

  transform(value: number | string, prefix = ''): any {
    if (typeof value === 'string') value = parseInt(value, 10);
    const suffix = this.translate.instant(prefix + this.i18nPluralPipe.transform(value, {
      '=0': 'ZERO',
      '=1': 'ONE',
      '=2': 'TWO',
      few: 'FEW',
      many: 'MANY',
      other: 'OTHER',
    }));
    return `${value} ${suffix}`;
  }
}
