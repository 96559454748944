import {FormlyExtension, FormlyFieldConfig} from '@ngx-formly/core';

export function prePopulate(field: FormlyFieldConfig) {
  if (!field.key) return;

  const path = (field.key as string).split('.');
  const key = path[path.length - 1];
  if (!field.templateOptions) field.templateOptions = {};
  if (!field.templateOptions.attributes) field.templateOptions.attributes = {};
  field.templateOptions.attributes['data-cy'] = key;
}

export const dataCyExtension: FormlyExtension = {prePopulate};
