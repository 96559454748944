import {ConfigOption} from '@ngx-formly/core';
// eslint-disable-next-line max-len
import {
  FormlyFieldsComponent,
  FormlyIconToggleGroupComponent,
  FormlyMatAddonsWrapperComponent,
  FormlyMatCardWrapperComponent,
  FormlyMatCardWrapperNoHeaderComponent,
  FormlyMatCheckboxComponent,
  FormlyMatDatepickerComponent,
  FormlyMatFormFieldWrapperComponent,
  FormlyMatIconBoxWrapperComponent,
  FormlyMatInputComponent,
  FormlyMatMultiCheckboxComponent,
  FormlyMatRadioComponent,
  FormlyMatSelectComponent,
  FormlyMatLabelWithValueComponent,
} from './components';
import {FormlyMatButtonComponent} from './components/mat-button.component';
import {FormlyMatToggleButtonGroupComponent} from './components/mat-toggle-button-group.component';
import {addonsExtension, dataCyExtension} from './extensions';

export const formlyConfig: ConfigOption = {
  wrappers: [
    {name: 'form-field', component: FormlyMatFormFieldWrapperComponent},
    {name: 'addons', component: FormlyMatAddonsWrapperComponent},
    {name: 'card', component: FormlyMatCardWrapperComponent},
    {name: 'card-no-header', component: FormlyMatCardWrapperNoHeaderComponent},
    {name: 'icon-box', component: FormlyMatIconBoxWrapperComponent},
  ],
  types: [
    {name: 'input', component: FormlyMatInputComponent},
    {name: 'checkbox', component: FormlyMatCheckboxComponent},
    {name: 'radio', component: FormlyMatRadioComponent},
    {name: 'select', component: FormlyMatSelectComponent},
    {name: 'datepicker', component: FormlyMatDatepickerComponent},
    {name: 'multicheckbox', component: FormlyMatMultiCheckboxComponent},
    {name: 'fields', component: FormlyFieldsComponent},
    {name: 'icon-toggle-group', component: FormlyIconToggleGroupComponent},
    {name: 'button', component: FormlyMatButtonComponent},
    {name: 'toggle-button-group', component: FormlyMatToggleButtonGroupComponent},
    {name: 'label-with-value', component: FormlyMatLabelWithValueComponent},
  ],
  extensions: [
    {name: 'addons', extension: addonsExtension},
    {name: 'data-cy', extension: dataCyExtension},
  ],
  extras: {
    lazyRender: true,
  },
};
