import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {isEmpty, mapValues, omitBy} from 'lodash';

// Returns a tree of any errors in control and children of control
export function getFormControlErrors(control: AbstractControl): object {
  if (control instanceof FormGroup) {
    const childErrors = mapValues(control.controls, c => getFormControlErrors(c));
    const pruned = omitBy(childErrors, isEmpty);
    return isEmpty(pruned) ? null : pruned;
  } else if (control instanceof FormArray) return control.controls.map(c => getFormControlErrors(c)).filter(Boolean);
  else {
    return control.errors;
  }
}
