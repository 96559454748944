import {MaxMortgage} from '@cmp/modules/editor/max-mortgage/max-mortgage.model';

export interface NonPersistentState {
  maxMortgage?: MaxMortgage;
}

export const initialNonPersistentState: NonPersistentState = {
  maxMortgage: {
    estateValue: undefined,
    savingsAmount: undefined,
    savings: false,
    estate: false,
  },
};
