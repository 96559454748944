import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'cmp-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @Input() class = '';

  @HostBinding('class') get classList() {
    return 'toolbar-wrapper mat-elevation-z1 ' + (this.class || '');
  }
}
