import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'formly-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldsComponent extends FieldArrayType {
  // TODO: add explicit constructor

  @HostBinding('class') get classList() {
    return 'fields ' + (this.field.fieldGroupClassName || '');
  }

  @HostBinding('style.marginRight') get marginRight() {
    return this.to.showRemove ? '40px' : null;
  }

  constructor() {
    super();
  }

  onAdd() {
    const model = this.to && this.to.createItem ? this.to.createItem(this.form.value) : null;
    this.add(null, model, {markAsDirty: true});
    if (this.to.onAdd) this.to.onAdd(this.field);
    if (this.to.change) this.to.change(this.field);
  }

  remove(i: number, options?: {markAsDirty: boolean}) {
    super.remove(i, options);
    if (this.to.change) this.to.change(this.field);
  }
}
