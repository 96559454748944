import {CommonModule, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {nonPersistentReducer} from '@cmp/store/non-persistent-store/non-persistent.reducer';
import {ComparatorService} from '@generated/controllers/Comparator';
import {ConfirmDialogModule} from '@lib/components';
import {FormlyComponentsModule} from '@lib/formly';
import {formlyConfig} from '@lib/formly/formly.config';
import {CommonEnvironment, environmentToken} from '@lib/models';
import {GtmService, SentryErrorHandler} from '@lib/services';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {FormlyMatDatepickerModule} from '@ngx-formly/material/datepicker';
import {FormlyMatToggleModule} from '@ngx-formly/material/toggle';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {build} from '../environments/version';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {ApiInterceptor} from './shared/services';
import {PrintInterceptor} from './shared/services/print-interceptor.service';

export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, undefined, `.json?v=${build.commit}`);
}

export function SentryErrorHandlerFactory(injectionToken: CommonEnvironment) {
  return environment.sentryDataSourceName ? new SentryErrorHandler(injectionToken) : new ErrorHandler();
}

registerLocaleData(localeCs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    // Translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'cs',
    }),
    // NgRx
    StoreModule.forRoot({nonPersistent: nonPersistentReducer}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25}) : [],
    // Material
    MatMomentDateModule,
    MatProgressSpinnerModule,
    // Formly
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatDatepickerModule,
    FormlyComponentsModule,
    FormlyModule.forRoot(formlyConfig),
    // App
    ConfirmDialogModule,
  ],

  providers: [
    PathLocationStrategy,
    {provide: LOCALE_ID, useValue: 'cs'},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: PrintInterceptor, multi: true},
    {provide: 'windowObject', useValue: window},
    {provide: 'authSettings', useValue: environment},
    {provide: 'gtmId', useValue: environment.gtmId},
    {provide: 'company', useValue: environment.company},
    {provide: environmentToken, useValue: environment},
    GtmService,
    {provide: ErrorHandler, useFactory: SentryErrorHandlerFactory, deps: [environmentToken]},
    ComparatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
