import {NonPersistentAction, NonPersistentActions} from '@cmp/store/non-persistent-store/non-persistent.actions';
import {initialNonPersistentState, NonPersistentState} from '@cmp/store/non-persistent-store/non-persistent.state';
import produce from 'immer';

export const nonPersistentProducer = (state: NonPersistentState, action: NonPersistentAction) =>
  NonPersistentActions.match(action, {
    SetMaxMortgage: maxMortgage => {
      state.maxMortgage = maxMortgage;
    },
    default: () => {},
  });

export function nonPersistentReducer(state = initialNonPersistentState, action: NonPersistentAction) {
  return produce(nonPersistentProducer)(state, action);
}
