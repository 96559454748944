import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'cmp-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarButtonComponent {
  @Input() type?: 'primary' | 'other';
  @Input() disabled = false;

  @HostBinding('style.marginLeft') get marginLeft() {
    return this.type === 'other' ? 'auto' : null;
  }
}
