import {ValidatorFn} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {InputType, ValueType} from '@lib/formly';
import {Option} from '@lib/models';
import {FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import {FormlyAttributeEvent} from '@ngx-formly/core/lib/components/formly.field.config';
import {get, isArray, isNil} from 'lodash';
import {getUnmaskedNumValue, MaskOptions} from './imask.utils';
import {extendFunction, updateObject} from './utils';

// TYPES

export type FormlyExpressionFnc = (model: any, formState: any, field?: FormlyFieldConfig) => any;

export function isFormlyExpressionFnc(obj: any): obj is FormlyExpressionFnc {
  return obj && typeof obj === 'function';
}

// TODO separate layout and style
export type FieldLayout = ColumnLayout | ColLayout | BlockLayout | BoxedStyle | ChippedStyle | GridLayout;
export type ColumnLayout = ['column', number?, number?];
export type ColLayout = ['col'];
export type BlockLayout = ['block'];
export type BoxedStyle = ['boxed'];
export type ChippedStyle = ['chipped'];
export type GridLayout = ['gridRows', number];

// HELPERS

export function getSubKey(key: string) {
  return key.substr(key.lastIndexOf('.') + 1);
}

export function getLabel(key: string, option?: string | number | boolean, translateParams?: Record<string, string>) {
  let label = (`FIELD.${key}${option != null ? `.OPTION.${option}` : ''}`).toUpperCase();
  if (translateParams) label += ';' + JSON.stringify(translateParams);
  return label;
}

export function getLayoutClass([layout, ...options]: FieldLayout) {
  return {
    column: `col-sm-${options[0] || 6} col-lg-${options[1] || 4}`,
    col: 'col-sm',
    block: 'field--block',
    boxed: 'field--boxed',
    chipped: 'field--chipped',
    gridRows: `grid-rows--${options[0]}`,
  }[layout];
}

export function createOptions(key: string, values: any[], icons?: (string | string[])[]): Option[] {
  return values.map((value, index) => ({
    value,
    label: getLabel(key, value === null ? 'null' : value),
    icon: icons && icons[index],
  }));
}

export function getModelValue({model, key}: FormlyFieldConfig) {
  return get(model, key);
}

export function getDefaultValue(config: FormlyFieldConfig) {
  const {type, templateOptions: to} = config;
  switch (type) {
    case 'input':
      const value = ({number: 0, tel: 0} as Record<string, number>)[to && to.type];
      return value != null ? value : '';
    case 'checkbox':
      return false;
    case 'radio':
    case 'select':
    case 'icon-toggle-group':
      return to && Array.isArray(to.options) && to.options[0] && to.options[0].value;
    default:
      return null;
  }
}

export function clearIfDefault(config: FormlyFieldConfig, {target}: any) {
  const defaultValue = getDefaultValue(config);
  const value = (typeof defaultValue === 'number') ? getUnmaskedNumValue(target.value) : target.value;
  if (value === defaultValue) {
    config.formControl.patchValue('', {emitEvent: false});
    config.formControl.setErrors(null);
  }
}

export function resetToDefaultIfEmpty(config: FormlyFieldConfig, {target}: any) {
  if (!target.value) config.formControl.patchValue(getDefaultValue(config), {emitEvent: false});
}

// TEMPLATES

export function template(content: string, layout?: FieldLayout, translate = false,
                         config?: Partial<FormlyFieldConfig>) {
  return {
    template: content,
    className: layout && getLayoutClass(layout),
    ...config,
    templateOptions: {noTranslate: !translate, ...(config || {}).templateOptions},
  };
}

export function heading(text: string, level = 4, layout?: FieldLayout,
                        config?: Partial<FormlyFieldConfig>): FormlyFieldConfig {
  return template(`<h${level}>${text}</h${level}>`, layout, true, config);
}

export function divider() {
  return template(`
    <div class="mat-divider mat-divider-horizontal"
      role="separator"
      aria-orientation="horizontal">
    </div>`);
}

// FIELDS

export function field(
  key: string,
  type?: string,
  layout?: FieldLayout,
  templateOptions?: FormlyTemplateOptions,
  label = getLabel(key),
  config?: Partial<FormlyFieldConfig>,
  hasTooltip = false,
  className?: string,
): FormlyFieldConfig {
  return {
    className: `${layout ? getLayoutClass(layout) : ''} ${className ? className : ''}`,
    key,
    type,
    ...config,
    templateOptions: {
      label,
      ...templateOptions,
      ...(config || {}).templateOptions,
      ...(hasTooltip ? {tooltip: `${label}.TOOLTIP`} : {}),
    },
  };
}

export function input(key: string, type?: InputType, layout?: FieldLayout, mask?: MaskOptions,
                      config?: Partial<FormlyFieldConfig>, hasTooltip = false, clearDefaultOnFocus = true,
                      className?: string) {
  if (clearDefaultOnFocus) {
    config = updateObject(config, {
      templateOptions: {
        focus: extendFunction(clearIfDefault, config?.templateOptions?.focus),
        blur: extendFunction(resetToDefaultIfEmpty, config?.templateOptions?.blur),
      },
    });
  }
  return field(key, 'input', layout, {type, mask}, undefined, config, hasTooltip, className);
}

export function labelWithValue(key: string, label: string, type: ValueType, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>) {
  return field(key, 'label-with-value', layout, {type}, label, config);
}

export function textarea(key: string, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>, hasTooltip = false, label?: string) {
  return field(key, 'textarea', layout, {autosize: true}, label, config, hasTooltip);
}

export function checkbox(key: string, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>, hasTooltip = false) {
  return field(key, 'checkbox', layout, undefined, undefined, config, hasTooltip);
}

export function iconCheckbox(key: string, labelIcon: string, layout?: FieldLayout,
                             config?: Partial<FormlyFieldConfig>) {
  return field(key, 'checkbox', layout, {label: null, labelIcon}, undefined, {wrappers: [], ...config});
}

export function checkboxGroup<T extends string | number | symbol>(
  key: string, type?: 'array', optionValues?: T[], showSelectAll = false, layout?: FieldLayout, optionColumns?: number,
  optionIcons?: Record<T, string>, config?: Partial<FormlyFieldConfig>,
) {
  const options = optionValues && createOptions(key, optionValues);
  if (showSelectAll && !config.wrappers) config.wrappers = [];
  return field(key, 'multicheckbox', layout, {type, options, optionIcons, optionColumns, showSelectAll}, undefined,
    config);
}

export function switchButton(key: string, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>) {
  return field(key, 'toggle', layout, undefined, undefined, config);
}

export function toggleButtonGroup(key: string, optionValues?: any[], layout?: FieldLayout,
                                  config?: Partial<FormlyFieldConfig>) {
  const options = optionValues && createOptions(key, optionValues);
  return field(key, 'toggle-button-group', layout, {options}, undefined, config);
}

export function radio(key: string, value: any, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>) {
  return field(key, 'radio', layout, {options: createOptions(key, [value])}, undefined, {wrappers: [], ...config});
}

export function radioGroup(key: string, optionValues?: any[], layout?: FieldLayout,
                           config?: Partial<FormlyFieldConfig>) {
  const options = optionValues && createOptions(key, optionValues);
  return field(key, 'radio', layout, {options}, undefined, config);
}

export function select<T extends string | number | symbol>(
  key: string, optionValues?: T[], mode: 'single' | 'multiple' = 'single', layout?: FieldLayout,
  showSelectAll?: boolean, optionIcons?: Record<T, string>, config?: Partial<FormlyFieldConfig>, hasTooltip = false,
) {
  const templateOptions = {
    options: optionValues && createOptions(key, optionValues),
    multiple: mode === 'multiple',
    selectAllOption: showSelectAll ? getLabel(key, 'all') : null,
    optionIcons,
  };
  return field(key, 'select', layout, templateOptions, undefined, config, hasTooltip);
}

export function datepicker(key: string, layout?: FieldLayout, config?: Partial<FormlyFieldConfig>, hasTooltip = false) {
  return field(key, 'datepicker', layout, undefined, undefined, config, hasTooltip);
}

export function iconToggleGroup(key: string, optionValues?: any[], optionIcons?: string[], layout?: FieldLayout,
                                config?: Partial<FormlyFieldConfig>) {
  const options = optionValues && createOptions(key, optionValues, optionIcons);
  return field(key, 'icon-toggle-group', layout, {options}, undefined, {...config, defaultValue: optionValues[0]});
}

export function button(label: string, click: FormlyAttributeEvent, layout?: FieldLayout, config?: FormlyFieldConfig, icon?: string) {
  return field(null, 'button', layout, {click, icon}, label, config);
}

export function fieldArray(key: string, item: FormlyFieldConfig, layout?: FieldLayout,
                           config?: Partial<FormlyFieldConfig>) {
  forEachField([item], f => {
    if (f.key && f.templateOptions && f.templateOptions.label) {
      f.templateOptions.label = f.templateOptions.label.replace('FIELD', getLabel(key));
      if (Array.isArray(f.templateOptions.options)) {
        f.templateOptions.options = f.templateOptions.options.map(o => ({
          ...o,
          label: o.label.replace('FIELD', getLabel(key)),
        }));
      }
    }
  });
  return field(key, 'fields', layout, null, null, {fieldArray: item, defaultValue: [{}], ...config});
}

// FIELD GROUPS

export function fieldGroup(fields: FormlyFieldConfig[], className?: string, fieldGroupClassName?: string,
                           config?: Partial<FormlyFieldConfig>): FormlyFieldConfig {
  return {className, fieldGroupClassName, fieldGroup: fields, ...config};
}

export function row(fields: FormlyFieldConfig[], className?: string, config?: Partial<FormlyFieldConfig>) {
  return fieldGroup(fields, className, 'row', config);
}

export function column(fields: FormlyFieldConfig[], [sm, lg]: [number, number] = [undefined, undefined],
                       fieldGroupClassName?: string, config?: Partial<FormlyFieldConfig>) {
  return fieldGroup(fields, getLayoutClass(['column', sm, lg]), fieldGroupClassName, config);
}

export function col(fields: FormlyFieldConfig | FormlyFieldConfig[], fieldGroupClassName?: string,
                    config?: Partial<FormlyFieldConfig>) {
  return fieldGroup(isArray(fields) ? fields : [fields], getLayoutClass(['col']), fieldGroupClassName, config);
}

export function flexRow(fields: FormlyFieldConfig[], className?: string, config?: Partial<FormlyFieldConfig>) {
  return fieldGroup(fields, className, 'field-group--row', config);
}

export function setFieldValues(fields: FormlyFieldConfig[], value: any | 'default' = 'default') {
  forEachField(fields, f => f.key && f.formControl &&
    f.formControl.setValue(value === 'default' ? (isNil(f.defaultValue) ? getDefaultValue(f) : f.defaultValue) : value));
}

export function toggleField(
  toggleKey: string,
  fieldConfig: FormlyFieldConfig,
  layout?: FieldLayout,
  resetValue: any | 'default' = 'default',
  toggleType: 'checkbox' | 'switchButton' = 'checkbox',
  toggleLayout?: FieldLayout,
  hasToggleTooltip = false,
): FormlyFieldConfig {
  return fieldGroup(
    [
      {switchButton, checkbox}[toggleType](toggleKey, null, {
        defaultValue: false,
        className: 'mr-2' + (toggleLayout ? ` ${getLayoutClass(toggleLayout)}` : ''),
        templateOptions: {
          change: (_, {checked}: MatCheckboxChange) => {
            setFieldValues([fieldConfig], checked ? 'default' : resetValue);
          },
        },
        expressionProperties: {
          'templateOptions.label': model => get(model, toggleKey) ? null : fieldConfig.templateOptions.label,
        },
      }, hasToggleTooltip),
      fieldGroup([fieldConfig], 'flex-grow-1', null,
        {expressionProperties: {className: m => get(m, toggleKey) ? 'flex-grow-1' : 'd-none'}}),
    ],
    (layout ? `${getLayoutClass(layout)} ` : '') + 'd-flex flex-column justify-content-center',
    'd-flex flex-row align-items-center',
  );
}

export function toggleFieldGroup(
  toggleKey: string,
  fields: FormlyFieldConfig[],
  layout?: FieldLayout,
  resetValue: any | 'default' = 'default',
  toggleType: 'checkbox' | 'switchButton' = 'checkbox',
  fieldGroupClass = 'field-group--sub',
  toggleClass?: string,
  hasToggleTooltip = false,
): FormlyFieldConfig {
  return fieldGroup([
    {switchButton, checkbox}[toggleType](toggleKey, null, {
      className: getLayoutClass(['block']) + (toggleClass ? ` ${toggleClass}` : ''),
      defaultValue: false,
      templateOptions: {
        change: (_, {checked}: MatCheckboxChange) => {
          if (!checked) setFieldValues(fields, checked ? 'default' : resetValue);
        },
      },
    }, hasToggleTooltip),
    fieldGroup(fields, fieldGroupClass, null, {expressionProperties: {className: m => get(m, toggleKey) ? fieldGroupClass : 'd-none'}}),
  ], layout && getLayoutClass(layout));
}

// OTHER

export function forEachField(
  fields: FormlyFieldConfig[],
  callbackFn: (field: FormlyFieldConfig, path?: string) => void,
  path?: string,
) {
  fields.forEach(f => {
    callbackFn(f, path);
    if (f.fieldGroup) forEachField(f.fieldGroup, callbackFn, path);
    if (f.fieldArray) forEachField([f.fieldArray], callbackFn, [path, f.key].filter(Boolean).join('.'));
  });
  return fields;
}

export function addValidators(fields: FormlyFieldConfig[], validators: Record<string, ValidatorFn[]>) {
  forEachField(fields, (f, path) => {
    if (f.key) {
      const key = [path, f.key].filter(Boolean).join('.');
      if (key && validators[key]) f.validators = {validation: validators[key]};
    }
  });
}
