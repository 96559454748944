import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormlyFieldCheckbox} from '@ngx-formly/material/checkbox/';

@Component({
  selector: 'formly-mat-checkbox',
  template: `
    <mat-checkbox
      [formControl]="formControl"
      [id]="id"
      [formlyAttributes]="field"
      [tabindex]="to.tabindex || 0"
      [indeterminate]="to.indeterminate && formControl.value === null"
      [color]="to.color"
      [labelPosition]="to.align || to.labelPosition">
      <div class="label-text">{{to.label}}</div>
      <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>

      <ng-container *ngIf="to.labelIcon">
        <mat-icon [svgIcon]="to.labelIcon"></mat-icon>
        <span style="display: inline-block; width: 0; height:0; overflow: hidden;">&#8288;</span>
      </ng-container>
    </mat-checkbox>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatCheckboxComponent extends FormlyFieldCheckbox {
  @ViewChild(MatCheckbox, {static: true}) checkbox!: MatCheckbox;
  defaultOptions = {
    templateOptions: {
      hideFieldUnderline: true,
      indeterminate: true,
      floatLabel: 'always',
      hideLabel: true,
      align: 'start', // start or end
      color: 'accent', // workaround for https://github.com/angular/components/issues/18465
    },
  };

  onContainerClick(event: MouseEvent): void {
    this.checkbox.focus();
    super.onContainerClick(event);
  }
}
