import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-mat-icon-box-wrapper',
  templateUrl: './mat-icon-box-wrapper.component.html',
  styleUrls: ['./mat-icon-box-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatIconBoxWrapperComponent extends FieldWrapper {
  @HostBinding('class') classList = 'icon-box';
}
