import produce from 'immer';
import {ModelationsAction, ModelationsActions} from './modelations.actions';
import {initialModelationsState, ModelationsState} from './modelations.state';

export const modelationsProducer = (state: ModelationsState, action: ModelationsAction) =>
  ModelationsActions.match(action, {
    SetModelations: modelations => {
      state.modelations = modelations;
    },
    SetParams: params => {
      state.params = params;
    },
    SetTotalCount: totalCount => {
      state.totalCount = totalCount;
    },
    default: () => {},
  });

export function modelationsReducer(state = initialModelationsState, action: ModelationsAction) {
  return produce(modelationsProducer)(state, action);
}
