<ng-container *ngFor="let field of field.fieldGroup; let i = index;">
  <mat-divider *ngIf="to.showDivider && i > 0; else separator"></mat-divider>

  <button *ngIf="!to.disabled && !to.readonly && to.showRemove &&  i + 1 > (to.minCount || 0)"
    class="fields__remove"
    type="button"
    mat-icon-button
    (click)="remove(i, {markAsDirty: true})">
    <mat-icon>delete</mat-icon>
  </button>

  <formly-field [field]="field">
  </formly-field>
</ng-container>
<ng-container *ngIf="!to.disabled && !to.readonly && to.showAdd">
  <mat-divider *ngIf="to.showDivider && field.fieldGroup.length > 0; else separator"></mat-divider>

  <button class="fields__add"
    type="button"
    mat-stroked-button
    (click)="onAdd()"
    [attr.data-cy]="to.addCy">
    <mat-icon svgIcon="add"></mat-icon>
    <span class="button-label">{{to.addLabel}}</span>
  </button>
</ng-container>

<ng-template #separator>
  <div *ngIf="!to.noSeparator"
    role="separator"></div>
</ng-template>
