import {CommonModule, CurrencyPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatPseudoCheckboxModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InfoFieldModule, TooltipModule} from '@lib/components';
import {FORMLY_CONFIG, FormlyModule} from '@ngx-formly/core';
import {FormlySelectModule} from '@ngx-formly/core/select';
import {TranslateService} from '@ngx-translate/core';
import {IMaskModule} from 'angular-imask';
import {registerFormlyTranslateExtension} from '../extensions';
import {FormlyFieldsComponent} from './fields/fields.component';
import {FormlyIconToggleGroupComponent} from './icon-toggle-group';
import {FormlyMatAddonsWrapperComponent} from './mat-addons-wrapper.component';
import {FormlyMatButtonComponent} from './mat-button.component';
import {FormlyMatCardWrapperComponent,FormlyMatCardWrapperNoHeaderComponent} from './mat-card-wrapper';
import {FormlyMatCheckboxComponent} from './mat-checkbox.component';
import {FormlyMatDatepickerComponent} from './mat-datepicker.component';
import {FormlyMatFormFieldWrapperComponent} from './mat-form-field-wrapper.component';
import {FormlyMatIconBoxWrapperComponent} from './mat-icon-box-wrapper';
import {FormlyMatInputComponent} from './mat-input';
import {FormlyMatLabelWithValueComponent} from './mat-label-with-value/mat-label-with-value.component';
import {FormlyMatMultiCheckboxComponent} from './mat-multi-checkbox.component';
import {FormlyMatRadioComponent} from './mat-radio.component';
import {FormlyMatSelectComponent} from './mat-select.component';
import {FormlyMatToggleButtonGroupComponent} from './mat-toggle-button-group.component';

@NgModule({
  declarations: [
    FormlyMatFormFieldWrapperComponent,
    FormlyMatAddonsWrapperComponent,
    FormlyMatCardWrapperComponent,
    FormlyMatCardWrapperNoHeaderComponent,
    FormlyMatIconBoxWrapperComponent,
    FormlyMatInputComponent,
    FormlyMatCheckboxComponent,
    FormlyMatRadioComponent,
    FormlyMatSelectComponent,
    FormlyMatDatepickerComponent,
    FormlyMatMultiCheckboxComponent,
    FormlyIconToggleGroupComponent,
    FormlyMatButtonComponent,
    FormlyFieldsComponent,
    FormlyMatToggleButtonGroupComponent,
    FormlyMatLabelWithValueComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    // Formly
    FormlyModule,
    FormlySelectModule,
    // Material
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatChipsModule,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    // IMask
    IMaskModule,
    // Lib
    TooltipModule,
    InfoFieldModule,
  ],
  providers: [
    {provide: FORMLY_CONFIG, multi: true, useFactory: registerFormlyTranslateExtension, deps: [TranslateService]},
    CurrencyPipe,
  ],
})
export class FormlyComponentsModule {}
