import {calculateLTV} from '@lib/utils';

export function calculateLTVPercent(credit: number, guarantee: number) {
  return (calculateLTV(credit, guarantee, 4) * 100).toFixed(2);
}

export function isCreditOrGuaranteeShortcut(value: number) {
  return value >= 0 && value < 1000;
}

export function getCreditOrGuaranteeFromShortcut(field: 'credit' | 'guarantee', value: number, otherValue?: number) {
  if (!isCreditOrGuaranteeShortcut(value)) return Math.floor(value);
  if (value > 99) return value * 1_000;
  if (value > 20) {
    const ltv = value / 100;
    if (field === 'credit') {
      return Math.round(ltv * otherValue ?? 0);
    } else {
      const guarantee = Math.round((1 / ltv * otherValue) ?? 0);
      return (otherValue / guarantee > ltv) ? guarantee + 1 : guarantee;
    }
  } else return value * 1_000_000;
}

export function calculateRevenuesCostDifference(revenues: number, flatRate: number): number {
  if (revenues > 2_000_000) {
    return Math.floor(revenues - flatRate * 2_000_000);
  }
  return Math.floor(revenues - flatRate * revenues);
}

export function calculateRealCosts(revenues: number, flatRate: number): number {
  if (revenues > 1_000_000) {
    return Math.floor(0.75 * (flatRate * 1_000_000));
  }
  return Math.floor(0.75 * (flatRate * revenues));
}

export function calculateTax(taxBase: number): number {
  return Math.max(Math.floor(0.15 * taxBase - 24_840), 0);
}
