import {Pipe, PipeTransform} from '@angular/core';
import {filter, ListIterateeCustom} from 'lodash';

@Pipe({
  name: 'filter',
})
export class FilterPipe<T> implements PipeTransform {

  transform(data: T[], predicate?: ListIterateeCustom<T, boolean>): any {
    return filter(data || [], predicate);
  }
}
