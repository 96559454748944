import {Option} from '@lib/models';
import {FormlyExpressionFnc, isFormlyExpressionFnc, stripHtmlTags} from '@lib/utils';
import {FormlyExtension, FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {get} from 'lodash';
import {map} from 'rxjs/operators';

export class FormlyTranslateExtension implements FormlyExtension {

  constructor(private translate: TranslateService) {}

  prePopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};
    if (to.noTranslate || to._translated) return;

    to._translated = true;

    if (!field.expressionProperties) field.expressionProperties = {};

    this.translateTemplate(field);
    if (!to.noTranslateLabel) this.translateLabel(field);
    if (!to.noTranslateOptions) this.translateOptions(field);
    this.translatePaths([
      'selectAllOption', 'addLabel', ['status', 'text'], 'tooltip', 'cardTooltip',
      'addonLeft.text', 'addonRight.text',
    ], field);
  }

  private translateTemplate({template, expressionProperties: exp}: FormlyFieldConfig) {
    if (!template) return;
    const templateText = stripHtmlTags(template);
    exp.template = this.translate
      .stream(templateText)
      .pipe(map(translatedText => template.replace(templateText, translatedText)));
  }

  private translateLabel({templateOptions: to = {}, expressionProperties: exp}: FormlyFieldConfig) {
    if (isFormlyExpressionFnc(exp['templateOptions.label'])) {
      const getLabel = exp['templateOptions.label'] as FormlyExpressionFnc;
      exp['templateOptions.label'] = (...args) => getLabel(...args) && this.translate.instant(getLabel(...args));
    } else if (to.label) exp['templateOptions.label'] = this.translate.stream(to.label);
  }

  private translateOptions({templateOptions: to = {}, expressionProperties: exp}: FormlyFieldConfig) {
    if (isFormlyExpressionFnc(exp['templateOptions.options'])) {
      const getOptions = exp['templateOptions.options'] as FormlyExpressionFnc;
      exp['templateOptions.options'] = (...args) => {
        return getOptions(...args).map((o: any) => o.label ? {...o, label: this.translate.instant(o.label)} : o);
      };
    } else if (to.options && Array.isArray(to.options)) {
      exp['templateOptions.options'] = () =>
        (to.options as Option[]).map((o: any) => o.label ? {...o, label: this.translate.instant(o.label)} : o);
    }
  }

  private translatePaths(
    paths: (string | string[])[], {templateOptions: to = {}, expressionProperties: exp}: FormlyFieldConfig,
  ) {
    paths.forEach(path => {
      let parentPath = path;
      let childProp: string;
      if (Array.isArray(path)) [parentPath, childProp] = path;
      const expPath = 'templateOptions.' + parentPath;
      const expValue = get(exp, expPath);
      const toValue = get(to, parentPath);

      if (isFormlyExpressionFnc(expValue)) {
        const getValue = exp[expPath] as FormlyExpressionFnc;
        exp[expPath] = (...args) => {
          const value = getValue(...args);
          if (!value) return;
          return childProp ? {...value, [childProp]: this.translate.instant(value[childProp])}
            : this.translate.instant(value);
        };
      } else if (toValue) exp[expPath] = this.translate.stream(toValue);
    });
  }
}

export function registerFormlyTranslateExtension(translate: TranslateService) {
  return {
    extensions: [{
      name: 'translate',
      extension: new FormlyTranslateExtension(translate),
    }],
  };
}
