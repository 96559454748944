import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {PanelComponent} from './panel.component';

@NgModule({
  declarations: [PanelComponent],
  imports: [
    CommonModule,
    // Material
    MatIconModule,
    MatButtonModule,
  ],
  exports: [PanelComponent],
})
export class PanelModule {}
