<!-- TODO | formlySelectOptions:field | async; -->
<div class="toggles"
  [formlyAttributes]="field"
  data-field-type="toggleGroup">
  <div *ngFor="let option of to.options"
    class="toggle"
    [class.toggle--selected]="isOptionSelected(option)"
    [class.toggle--disabled]="formControl.disabled"
    [attr.data-value]="option.value">
    <input type="radio"
      class="toggle__radio"
      [id]="getOptionId(option)"
      [name]="key"
      [value]="option.value"
      [formControl]="formControl" />

    <label class="toggle__label"
      [for]="getOptionId(option)">
      {{option.label}}
      <mat-icon class="toggle__icon"
        [svgIcon]="option.icon"></mat-icon>
    </label>
  </div>
</div>