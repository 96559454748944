import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormlyFieldSelect} from '@ngx-formly/material/select/';

@Component({
  selector: 'formly-mat-select',
  template: `
    <ng-template #selectAll let-selectOptions="selectOptions">
      <mat-option (click)="toggleSelectAll(selectOptions); change({source: matselect, value: selectOptions})">
        <mat-pseudo-checkbox class="mat-option-pseudo-checkbox" [state]="getSelectAllState(selectOptions)">
        </mat-pseudo-checkbox>
        {{to.selectAllOption}}
      </mat-option>
    </ng-template>

    <mat-select
      #matselect
      [id]="id"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabindex]="to.tabindex"
      [required]="to.required"
      [compareWith]="to.compareWith"
      [multiple]="to.multiple"
      (selectionChange)="change($event)"
      [errorStateMatcher]="errorStateMatcher"
      [aria-labelledby]="_getAriaLabelledby()"
      [disableOptionCentering]="to.disableOptionCentering"
      [panelClass]="to.panelClass"
    >
      <ng-container *ngIf="to.options | formlySelectOptions: field | async as selectOptions">
        <ng-container
          *ngIf="to.multiple && to.selectAllOption"
          [ngTemplateOutlet]="selectAll"
          [ngTemplateOutletContext]="{ selectOptions: selectOptions }"
        >
        </ng-container>

        <ng-container *ngFor="let item of selectOptions">
          <mat-optgroup *ngIf="item.group" [label]="item.label">
            <mat-option *ngFor="let child of item.group" [value]="child.value" [disabled]="child.disabled">
              <mat-icon *ngIf="to.optionIcons && to.optionIcons[child.value]"
                        [svgIcon]="to.optionIcons[child.value]">
              </mat-icon>
              {{child.label}}
            </mat-option>
          </mat-optgroup>

          <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled">
            <mat-icon *ngIf="to.optionIcons && to.optionIcons[item.value]"
                      [svgIcon]="to.optionIcons[item.value]">
            </mat-icon>
            {{item.label}}
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatSelectComponent extends FormlyFieldSelect {}
