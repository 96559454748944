import {GetAllModelationsParams} from '@generated/controllers/Comparator';
import {ModelationList} from '@generated/model';

export interface ModelationsState {
  modelations?: ModelationList[];
  params: GetAllModelationsParams;
  totalCount?: number;
}

export const initialModelationsState: ModelationsState = {
  params: {},
};
