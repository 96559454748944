import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Option} from '@lib/models';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-icon-toggle-group',
  templateUrl: 'icon-toggle-group.component.html',
  styleUrls: ['icon-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyIconToggleGroupComponent extends FieldType {

  getOptionId(option: Option) {
    return `${this.key}-${option.value}`;
  }

  isOptionSelected(option: Option) {
    return this.formControl.value === option.value;
  }
}
