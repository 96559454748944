import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InfoFieldComponent} from './info-field.component';

@NgModule({
  declarations: [InfoFieldComponent],
  imports: [CommonModule],
  exports: [InfoFieldComponent],
})
export class InfoFieldModule {}
