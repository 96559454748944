import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginService} from '@lib/services';
import {get} from 'lodash';
import {Observable, throwError} from 'rxjs';
import {catchError, concatMap, map, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // auth call is not intercepted
    // if (request.url.match(`^${environment.authUrl}`)) return next.handle(request);
    if (!request.url.startsWith('/api')) return next.handle(request);

    // set base url for api calls
    // set oauth header
    return this.loginService.accessToken.pipe(
      map(token => request.clone({
        url: environment.apiBaseUrl + request.url,
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      })),
      take(1), // request observables must be finite
      concatMap(authorizedRequest => next.handle(authorizedRequest)),
      // final error message
      catchError((e: HttpErrorResponse) => {
        // invalid code, refresh token
        if (this.isErrorOfType(e, 400, 'invalid_grant')) {
          this.loginService.logout();
          // logout on invalid token
        } else if (this.isErrorOfType(e, 401)) {
          this.loginService.logout();
        } else {
          // TODO display server communication error message
        }

        return throwError(e);
      }),
    );
  }

  private isErrorOfType(e: HttpErrorResponse, status: number, authError?: string) {
    if (e.status !== status) return false;
    if (authError) {
      // Makro env
      const inBody = get(e, 'error.error') === authError;
      // TODO remove once dev and Makro env implementations harmonize
      const wwwAuthHeader = e.headers.get('www-authenticate');
      const inHeader = wwwAuthHeader &&
        wwwAuthHeader
          .split(', ')
          .includes(`error="${authError}"`);

      if (!inBody && !inHeader) return false;
    }

    return true;
  }
}
