<h1 *ngIf="data?.title"
  mat-dialog-title>{{data.title}}</h1>

<div *ngIf="data?.text"
  mat-dialog-content>
  <p class="dialog-text">{{data.text}}</p>
</div>

<mat-dialog-actions>
  <button mat-flat-button
    class="action-button"
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    data-cy="confirm">{{data?.confirmLabel}}</button>

  <button *ngIf="!data?.hideCancelButton"
    mat-stroked-button
    class="action-button"
    [mat-dialog-close]="false"
    data-cy="cancel">{{data?.cancelLabel}}</button>
</mat-dialog-actions>
