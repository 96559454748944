import {Routes} from '@angular/router';
import {LoginService} from '@lib/services';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/secured/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/login/logout',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./layouts/public/public-layout.module').then(m => m.PublicLayoutModule),
  },
  {
    path: 'secured',
    canActivate: [LoginService],
    loadChildren: () => import('./layouts/secured/secured-layout.module').then(m => m.SecuredLayoutModule),
  },
];
