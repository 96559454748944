import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {EditorModel} from '../editor.model';
import {EditorState} from './editor.state';

export const featureName = 'editor';

export const selectState = createFeatureSelector<EditorState>(featureName);

export const selectModelation = createSelector(selectState, state => state.modelation);

export const selectUi = createSelector(selectState, state => state.ui);

export const selectModel: MemoizedSelector<object, EditorModel> = createSelector(selectModelation, selectUi,
  (modelation, ui) => ({modelation, ui}));

export const selectStatusInfo = createSelector(selectState, state => state.statusInfo);

export const selectStatus = createSelector(selectStatusInfo, statusInfo => statusInfo.status);

export const selectBankIncomes = createSelector(selectState, state => state.bankIncomes);

export const selectBankExpenditures = createSelector(selectState, state => state.bankExpenditures);

export const selectMainApplicant = createSelector(selectState, state => state.modelation.mainApplicant);

export const selectCredit = createSelector(selectState, state => state.modelation.credit);
