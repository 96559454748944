import produce from 'immer';
import {ReportAction, ReportActions} from './report.actions';
import {initialReportState, ReportState} from './report.state';

export const reportProducer = (state: ReportState, action: ReportAction) => ReportActions.match(action, {
  SetFilter: filter => {
    state.filter = filter;
  },
  UpdateFilter: uiFilter => {
    if (uiFilter.banks) {
      state.filter.banks = state.filter.banks.map(b => ({...b, isSet: uiFilter.banks[b.bankCode] || false}));
    }
    if (uiFilter.sortOrder) state.filter.sortOrder = uiFilter.sortOrder;
    if (uiFilter.display) state.filter.display = {...state.filter.display, ...uiFilter.display};
  },
  ToggleResult: id => {
    const result = state.results.results.find(r => r.id === id);
    result.isRemoved = !result.isRemoved;
  },
  SetResults: results => {
    state.results = results;
  },
  default: () => {},
});

export function reportReducer(state = initialReportState, action: ReportAction) {
  return produce(reportProducer)(state, action);
}
