import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'formly-mat-card-wrapper-no-header',
  templateUrl: './mat-card-wrapper.component.html',
  styleUrls: ['./mat-card-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatCardWrapperNoHeaderComponent extends FieldWrapper {
  showHeader = false;
  @HostBinding('class') classList = 'mat-card-wrapper';
}
