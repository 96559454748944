import {RiskInsuranceConfig} from '@generated/defs/RiskInsuranceConfig';
import {ModeSelectionModeEnum, RiskInsurance, SelectionMode, TypeRiskInsuranceEnum} from '@generated/model';
import {flatMap, intersection, maxBy} from 'lodash';
import {RiskInsuranceArea} from './editor.model';

export function getRiskInsuranceConfig(configs: RiskInsuranceConfig[], bankCode: string) {
  return configs.find(c => c.code === bankCode);
}

export function getRiskInsuranceRowCount(config: RiskInsuranceConfig): number {
  if (!config) return 0;
  return 2 + config.riskTypes.length +
    (config.paymentTypes ? 1 : 0);
}

export function getAllRiskInsuranceTypes(configs: RiskInsuranceConfig[]) {
  return flatMap(configs, c => c.riskTypes);
}

export function getSharedAreas(configs: RiskInsuranceConfig[]) {
  return intersection(...getAllRiskInsuranceTypes(configs)) as RiskInsuranceArea[];
}

export function getStandardRiskInsurance(config: RiskInsuranceConfig) {
  return config.standardInsurance;
}

export function getBestMatchingRiskInsurance(config: RiskInsuranceConfig, areas: RiskInsuranceArea[]) {
  return config.riskTypes
    .indexOf(maxBy(config.riskTypes, t => intersection(t, areas).length)) + 1 as TypeRiskInsuranceEnum;
}

export function getMatchingRiskInsurance(
  config: RiskInsuranceConfig, mode: 'standard' | 'matching', areas: RiskInsuranceArea[],
): TypeRiskInsuranceEnum {
  switch (mode) {
    case 'standard': return getStandardRiskInsurance(config);
    case 'matching': return getBestMatchingRiskInsurance(config, areas);
  }
}

export function hasExactlyMatchingRiskInsurance(config: RiskInsuranceConfig, areas: RiskInsuranceArea[]) {
  return !!config.riskTypes.find(t => intersection(t, areas).length === areas.length);
}

export function matchesSelectionMode(
  insurances: RiskInsurance[],
  configs: RiskInsuranceConfig[],
  {mode, areas}: SelectionMode,
) {
  if (mode === 'manual') return true;
  return insurances.every(r => r.type === getMatchingRiskInsurance(
    getRiskInsuranceConfig(configs, r.bankCode), mode, areas));
}

export function getSelectionMode(
  insurances: RiskInsurance[], configs: RiskInsuranceConfig[], areas: RiskInsuranceArea[],
): ModeSelectionModeEnum {
  configs = insurances.map(r => getRiskInsuranceConfig(configs, r.bankCode));
  if (insurances.every((r, i) => r.type === getStandardRiskInsurance(configs[i]))) {
    return 'standard';
  }
  if (insurances.every((r, i) => r.type === getBestMatchingRiskInsurance(configs[i], areas))) {
    return 'matching';
  }
  return 'manual';
}

export function selectMatchingRiskInsurances(
  insurances: RiskInsurance[], configs: RiskInsuranceConfig[], {mode, areas}: SelectionMode,
): RiskInsurance[] {
  if (mode === 'manual') return insurances;
  return insurances.map(r => ({
    ...r,
    type: getMatchingRiskInsurance(configs.find(c => c.code === r.bankCode), mode, areas),
  }));
}

export function getBanksWithoutMatchingRiskInsurance(
  configs: RiskInsuranceConfig[], {mode, areas}: SelectionMode,
): string[] {
  if (mode !== 'matching') return [];
  return configs.filter(c => !hasExactlyMatchingRiskInsurance(c, areas)).map(c => c.code);
}
