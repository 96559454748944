export enum MessageType {
  INFO = 'message-info',
  WARNING = 'message-warning',
  ERROR = 'message-error',
  OFFLINE = 'message-offline',
  ONLINE = 'message-online',
  TIMEOUT = 'message-timeout',
  QUEUE = 'message-queue',
  NEW_VERSION = 'message-new-version',
}

export interface NotificationMessage {
  message: string;
  type: MessageType;
  duration?: number;
}
