import {ModelationResultCollection, ReportFilter} from '@generated/model';
import {ofType, unionize, UnionOf} from 'unionize';
import {ReportUiFilter} from '../report.model';

export const ReportActions = unionize({
  SetFilter: ofType<ReportFilter>(),
  UpdateFilter: ofType<Partial<ReportUiFilter>>(),
  ToggleResult: ofType<number>(),
  SetResults: ofType<ModelationResultCollection>(),

}, {tag: 'type', value: 'payload'});

export type ReportAction = UnionOf<typeof ReportActions>;
