import {NgModule} from '@angular/core';
import {PrintPreferencesComponent} from './print-preferences.component';

@NgModule({
  declarations: [
    PrintPreferencesComponent,
  ],
  exports: [
    PrintPreferencesComponent,
  ],
})
export class PrintPreferencesModule {}
