import {GetAllModelationsParams} from '@generated/controllers/Comparator';
import {ModelationList} from '@generated/model';
import {ofType, unionize, UnionOf} from 'unionize';

export const ModelationsActions = unionize({
  SetModelations: ofType<ModelationList[]>(),
  SetParams: ofType<GetAllModelationsParams>(),
  SetTotalCount: ofType<number>(),
}, {tag: 'type', value: 'payload'});

export type ModelationsAction = UnionOf<typeof ModelationsActions>;
