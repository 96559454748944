import {
  AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild,
} from '@angular/core';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {VerticalTabComponent} from '../vertical-tab/vertical-tab.component';

@Component({
  selector: 'mat-vertical-tab-group',
  templateUrl: './vertical-tab-group.component.html',
  styleUrls: ['./vertical-tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalTabGroupComponent implements AfterViewInit {
  @Input() selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();
  @Output() selectedTabChange = new EventEmitter<MatTabChangeEvent>();

  inkBarTop: string;

  @ViewChild(MatTabGroup, {static: true}) matTabGroup: MatTabGroup;
  @ContentChildren(VerticalTabComponent) protected tabs: QueryList<VerticalTabComponent>;

  ngAfterViewInit() {
    const matTabsFromQueryList = this.tabs.map(tab => tab.matTab);
    this.matTabGroup._tabs.reset([matTabsFromQueryList]);
    this.matTabGroup._tabs.notifyOnChanges();
  }

  onSelectedIndexChange(index: number) {
    this.inkBarTop = index * 6 + 'rem';
  }
}
