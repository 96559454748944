import {CommonModule, I18nPluralPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {SeparateSymbolPipe} from './separate-symbol.pipe';
import {TranslatePluralPipe} from './translate-plural.pipe';

// TODO move to shared
@NgModule({
  declarations: [
    TranslatePluralPipe,
    SeparateSymbolPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TranslatePluralPipe,
    SeparateSymbolPipe,
  ],
  providers: [
    I18nPluralPipe,
    TranslatePluralPipe,
    SeparateSymbolPipe,
  ],
})
export class PipesModule {}
