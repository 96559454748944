<mat-tab>
  <ng-template mat-tab-label>
    <div class="tab-label"
      [attr.data-cy]="dataCy">
      <mat-icon *ngIf="icon || svgIcon"
        class="tab-label__icon"
        [svgIcon]="svgIcon">{{icon}}</mat-icon>

      <span class="tab-label__text">{{label}}</span>
    </div>
  </ng-template>

  <ng-content></ng-content>
</mat-tab>