import {AdvisorBasicInformation} from '@lib/services';
import * as Sentry from '@sentry/browser';
import {environment} from '../../../../mortgage-comparator-app/src/environments/environment';

export function setErrorLoggerAdvisor(advisorInformation: AdvisorBasicInformation) {
  if (!environment.sentryDataSourceName) return;

  Sentry.configureScope(scope => {
    scope.setUser(advisorInformation);
  });
}
