import {Inject, Injectable, isDevMode} from '@angular/core';
import {LoginService} from '@lib/services';
import {values} from 'lodash';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Company} from '../models';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  company$ = of(this.getCompany());

  theme$ = this.company$.pipe(
    map(company => this.getTheme(company)),
  );

  favicon$ = this.company$.pipe(
    map(company => this.getFavicon(company)),
  );

  logo$ = this.company$.pipe(
    map(company => this.getLogo(company)),
  );

  logoSmall$ = this.company$.pipe(
    map(company => this.getLogoSmall(company)),
  );

  private faviconsPath = 'assets/favicon/';
  private logosPath = 'assets/img/';

  private logos: Record<Company | 'test', string> = {
    [Company.Gepard]: 'logo-gepard.svg',
    [Company.Kapitol]: 'logo-kapitol.svg',
    [Company.Slavia]: 'logo-slavia.svg',
    test: 'logo-test.svg',
  };

  private logosSmall: Record<Company | 'test', string> = {
    [Company.Gepard]: 'logo-gepard-small.svg',
    [Company.Kapitol]: 'logo-kapitol-small.svg',
    [Company.Slavia]: 'logo-slavia-small.svg',
    test: 'logo-test-small.svg',
  };

  private companyCode: Record<Company, string> = {
    [Company.Gepard]: 'gepard',
    [Company.Kapitol]: 'kapitol',
    [Company.Slavia]: 'slavia',
  };

  constructor(
    @Inject('company') private company: Company,
    private loginService: LoginService,
  ) {}

  private getTheme(company: Company): string {
    return this.companyCode[company];
  }

  private getFavicon(company: Company): string {
    return `${this.faviconsPath}favicon-${this.companyCode[company]}.ico`;
  }

  private getLogo(company: Company): string {
    return this.logosPath + this.logos[isDevMode() ? 'test' : company];
  }

  private getLogoSmall(company: Company): string {
    return this.logosPath + this.logosSmall[isDevMode() ? 'test' : company];
  }

  private getCompany(): Company {
    const companyContext = this.loginService.getCompanyContext() as Company;
    return values(Company).includes(companyContext) ? companyContext : this.company;
  }
}
