<mat-tab-group #matTabGroup
  class="mat-tab-group--vertical"
  [(selectedIndex)]="selectedIndex"
  [animationDuration]="0"
  (selectedIndexChange)="selectedIndexChange.emit($event); onSelectedIndexChange($event)"
  (selectedTabChange)="selectedTabChange.emit($event)">
  <ng-content #tabs
    select="mat-vertical-tab">
  </ng-content>
</mat-tab-group>

<div class="mat-vertical-inkbar"
  [style.top]="inkBarTop">
</div>