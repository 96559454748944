<mat-card>
  <mat-card-header *ngIf="showHeader">
    <div *ngIf="to.status"
      class="card__status card__status--{{to.status.icon}}"
      mat-card-avatar
      [matTooltip]="to.status.text">
      <mat-icon class="card__status__icon">{{to.status.icon}}</mat-icon>
    </div>

    <mat-card-title>
      {{to.label}}
    </mat-card-title>

    <shd-tooltip *ngIf="to.cardTooltip"
      class="card__tooltip"
      matIcon="info"
      direction="left"
      width="25rem"
      textAlign="left"
      [stopClickPropagation]="true">
      {{to.cardTooltip}}
    </shd-tooltip>
  </mat-card-header>

  <mat-divider  *ngIf="showHeader" class="mat-divider--dense"></mat-divider>

  <mat-card-content>
    <div class="card__content">
      <ng-container #fieldComponent></ng-container>
    </div>
  </mat-card-content>
</mat-card>
