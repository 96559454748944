import {NgModule} from '@angular/core';
// own classes
import {OffClickDirective} from '@lib/directives/off-click';

@NgModule({
  declarations: [
    OffClickDirective,
  ],
  exports: [
    OffClickDirective,
  ],
})
export class DirectivesModule {
}
